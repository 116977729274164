// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import cn from "clsx";
import * as Yup from "yup";
import { cloneDeep } from "lodash";
import { Field, FieldArray, Form, Formik, FormikHandlers } from "formik";
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NumberFormatValues } from "react-number-format";

import { IProduct, IPropertyDetails } from "data/schemas";

import { Input, Select } from "_metronic/_partials/controls";
import { toAbsoluteUrl } from "_metronic/_helpers";

import { NumberInput } from "app/_utils/formUtils";
import { PROPERTY_TYPES, HEATING_TYPES } from "app/_utils/listUtils";

import { TextEditor } from "app/_components/TextEditor/TextEditor";
import { commentToolbar } from "app/_components/TextEditor/config";

import { useEntityDeleteDialogContext } from "app/modules/PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";

import {
  FormSection,
  FormSectionRegistryRow,
  FormSectionRow,
  FormSectionField,
} from "./FormSection";

//----------------------------------------------------------------------------//

const defaultParkingItem = { type: "INDOOR", price: 0, parcel: "", landRegistryNumber: "" };

const MAX_SQM = 1000000000;
const YUP_MAX_SQM = Yup.number().max(MAX_SQM);

//----------------------------------------------------------------------------//

interface IInputField extends HTMLElement {
  name: string;
  value: any;
}

interface INumberFieldChangeHandlerParam {
  name: string;
  handleChange: FormikHandlers["handleChange"];
  defaultValue?: number;
  disabled?: boolean;
}

interface IBaseParkingButtonClickHandlerParam {
  values: IProduct;
}

interface IRemoveParkingButtonClickHandlerParam extends IBaseParkingButtonClickHandlerParam {
  index: number;
  remove<T>(index: number): T | undefined;
}

interface IAddParkingButtonClickHandlerParam extends IBaseParkingButtonClickHandlerParam {
  push: (obj: any) => void;
}

interface IButtonClickFieldChangeHandlerParam {
  name: string;
  value?: string;
}

//----------------------------------------------------------------------------//

export interface ProductEditInformationFormProps {
  submitProduct: Function;
  saveProductFields: Function;
  product: IProduct;
  disabled: boolean;
  isLoading?: boolean;
}

export const ProductEditInformationForm: React.FunctionComponent<
  ProductEditInformationFormProps
> = ({ submitProduct, saveProductFields, product, disabled }) => {
  const intl = useIntl();

  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();

  const SUFFIX_EURO_CURRENCY = " €";
  const SUFFIX_THOUSANDS = " / 1,000";
  const SUFFIX_UNIT_AREA = " " + intl.formatMessage({ id: "COMMON.AREA.UNIT" });

  const YUP_MIN_1_MAX_150_CHARS = Yup.string()
    .min(1, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 1`)
    .max(150, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 150`);

  // Validation schema
  const ProductEditSchema = Yup.object().shape({
    propertyDetails: Yup.object().shape({
      rooms: YUP_MAX_SQM,
      bedrooms: YUP_MAX_SQM,
      bathrooms: YUP_MAX_SQM,

      livingSurface: YUP_MAX_SQM,
      acre: YUP_MAX_SQM,

      gardenSurface: YUP_MAX_SQM,
      balconySurface: YUP_MAX_SQM,
      terraceSurface: YUP_MAX_SQM,

      floor: YUP_MIN_1_MAX_150_CHARS,

      energyPerformanceClass: YUP_MIN_1_MAX_150_CHARS,
      environmentalPerformanceClass: YUP_MIN_1_MAX_150_CHARS,
      thermalInsulationClass: YUP_MIN_1_MAX_150_CHARS,
    }),
  });

  //--------------------------------------------------------------------------//

  const customFieldChangeHandler = (fieldName: string, fieldValue: any) => {
    if (saveProductFields !== undefined && fieldName) {
      saveProductFields(fieldName, fieldValue);
    }
  };

  const fieldChangeHandler =
    <T extends IInputField>(handleChange: FormikHandlers["handleChange"]) =>
    (e: React.ChangeEvent<T>) => {
      handleChange(e);
      customFieldChangeHandler(e.target.name, e.target.value);
    };

  const numberFieldChangeHandler =
    ({
      name,
      defaultValue = undefined,
      handleChange,
      disabled = false,
    }: INumberFieldChangeHandlerParam) =>
    ({ floatValue: fieldValue = defaultValue }: NumberFormatValues) => {
      handleChange({
        target: {
          name,
          value: fieldValue,
        },
      });

      if (disabled) {
        debugger;
        return;
      }

      customFieldChangeHandler(name, fieldValue);
    };

  //--------------------------------------------------------------------------//

  const removeParkingLogic =
    ({ values, index, remove }: IRemoveParkingButtonClickHandlerParam) =>
    () => {
      remove(index);

      const clonedList = cloneDeep(values.propertyDetails.parkings)!;
      clonedList.splice(index, 1);

      customFieldChangeHandler(`propertyDetails.parkings`, clonedList);
    };

  const removeParkingButtonClickHandler =
    ({ values, index, remove }: IRemoveParkingButtonClickHandlerParam) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      setDeleteEntityDialog({
        entityType: "PARKING",
        onDelete: removeParkingLogic({ values, index, remove }),
      });
    };

  const addParkingButtonClickHandler =
    ({ values, push }: IAddParkingButtonClickHandlerParam) =>
    (_: React.MouseEvent<HTMLButtonElement>) => {
      push(defaultParkingItem);

      const clonedList = cloneDeep(values.propertyDetails.parkings)!;
      clonedList.push(defaultParkingItem);

      customFieldChangeHandler(`propertyDetails.parkings`, clonedList);
    };

  const buttonClickFieldChangeHandler =
    ({ name, value = "" }: IButtonClickFieldChangeHandlerParam) =>
    (_: React.MouseEvent<HTMLButtonElement>) => {
      customFieldChangeHandler(name, value);
    };

  //--------------------------------------------------------------------------//

  const onSubmitHandler = () => {
    if (!disabled && !!submitProduct) {
      submitProduct();
    }
  };

  //--------------------------------------------------------------------------//

  return (
    <Formik
      enableReinitialize={true}
      initialValues={product}
      validationSchema={ProductEditSchema}
      onSubmit={onSubmitHandler}
    >
      {({ handleChange, values }) => (
        <Form className="form form-label-right">
          {/* @begin: info */}
          <FormSection>
            <FormSectionRow>
              <FormSectionField>
                <Select
                  name="propertyDetails.propertyType"
                  label={intl.formatMessage({
                    id: "PRODUCT.TYPE",
                  })}
                  onChange={fieldChangeHandler(handleChange)}
                  disabled={disabled}
                  customFeedbackLabel={true}
                >
                  {Object.keys(PROPERTY_TYPES).map((value) => (
                    <option key={value} value={value}>
                      {intl.formatMessage({ id: (PROPERTY_TYPES as any)?.[value] })}
                    </option>
                  ))}
                </Select>
              </FormSectionField>

              <FormSectionField>
                <Select
                  name="propertyDetails.heatingType"
                  label={intl.formatMessage({
                    id: "PRODUCT.INFO.HEATING_TYPE",
                  })}
                  onChange={fieldChangeHandler(handleChange)}
                  disabled={disabled}
                  customFeedbackLabel={true}
                >
                  {Object.keys(HEATING_TYPES).map((value) => (
                    <option key={value} value={value}>
                      {intl.formatMessage({ id: (HEATING_TYPES as any)[value] })}
                    </option>
                  ))}
                </Select>
              </FormSectionField>

              <FormSectionField label={<FormattedMessage id="PRODUCT.INFO.LIVING_SURFACE" />}>
                <NumberInput
                  className="form-control text-right"
                  value={values?.propertyDetails?.livingSurface}
                  onValueChange={numberFieldChangeHandler({
                    name: "propertyDetails.livingSurface",
                    defaultValue: 0,
                    handleChange,
                  })}
                  decimalScale={2}
                  disabled={disabled}
                  suffix={SUFFIX_UNIT_AREA}
                  placeholder=""
                />
              </FormSectionField>

              <FormSectionField label={<FormattedMessage id="PRODUCT.INFO.ACRE" />}>
                <NumberInput
                  className="form-control text-right"
                  value={values?.propertyDetails?.acre}
                  onValueChange={numberFieldChangeHandler({
                    name: "propertyDetails.acre",
                    defaultValue: 0,
                    handleChange,
                  })}
                  decimalScale={2}
                  disabled={disabled}
                  placeholder=""
                />
              </FormSectionField>
            </FormSectionRow>

            <FormSectionRow>
              {[
                { key: "housingProject", label: "PRODUCT.INFO.HOUSING_PROJECT" },
                { key: "parcel", label: "PRODUCT.INFO.LOT" },
                { key: "landRegistryNumber", label: "PRODUCT.INFO.LAND_REGISTRY_NUMBER" },
              ].map(({ key, label }, index) => (
                <FormSectionField key={index}>
                  <Field
                    key={index}
                    name={`propertyDetails.${key}`}
                    component={Input}
                    onChange={fieldChangeHandler(handleChange)}
                    label={intl.formatMessage({
                      id: label,
                    })}
                    disabled={disabled}
                  />
                </FormSectionField>
              ))}

              <FormSectionField label={<FormattedMessage id="PRODUCT.INFO.THOUSANDS" />}>
                <NumberInput
                  className="form-control text-right"
                  suffix={SUFFIX_THOUSANDS}
                  value={values?.propertyDetails.thousands}
                  onValueChange={numberFieldChangeHandler({
                    name: "propertyDetails.thousands",
                    handleChange,
                  })}
                  disabled={disabled}
                />
              </FormSectionField>
            </FormSectionRow>
          </FormSection>
          {/* @end: info */}

          {/* @begin: floor and rooms */}
          <FormSection title={<FormattedMessage id={"PROPERTY.INFO.FLOOR_AND_ROOMS"} />}>
            <FormSectionRow>
              {[
                { label: "PRODUCT.INFO.ROOMS", key: "rooms" },
                { label: "PRODUCT.INFO.BEDROOMS", key: "bedrooms" },
                { label: "PRODUCT.INFO.BATHROOMS", key: "bathrooms" },
                { label: "PRODUCT.INFO.FLOOR", key: "floor" },
              ].map((data, index) => (
                <FormSectionField key={index} label={<FormattedMessage id={data.label} />}>
                  <NumberInput
                    className={"form-control text-right"}
                    value={values.propertyDetails[data.key as keyof IPropertyDetails] ?? 0}
                    onValueChange={numberFieldChangeHandler({
                      name: `propertyDetails.${data.key}`,
                      handleChange,
                    })}
                    decimalScale={0}
                    disabled={disabled}
                    placeholder=""
                  />
                </FormSectionField>
              ))}
            </FormSectionRow>
          </FormSection>
          {/* @end: floor and rooms */}

          {/* @begin: parking */}
          {(values?.urls?.length > 0 || !disabled) && (
            <FormSection title={<FormattedMessage id={"PROPERTY.INFO.PARKING"} />}>
              <FieldArray name={"propertyDetails.parkings"}>
                {({ remove, push }) => (
                  <div>
                    {values.propertyDetails.parkings?.map(
                      (parking: { type: string; price: number }, index: number) => (
                        <div key={index}>
                          <div className={"d-flex justify-content-berween my-4"}>
                            <div
                              className={
                                "w-100 mx-2 form-row justify-content-between justify-content-lg-start"
                              }
                            >
                              <FormSectionField>
                                <Field
                                  name={`propertyDetails.parkings.${index}.parcel`}
                                  onChange={fieldChangeHandler(handleChange)}
                                  label={intl.formatMessage({
                                    id: "PRODUCT.INFO.LOT",
                                  })}
                                  component={Input}
                                  disabled={disabled}
                                />
                              </FormSectionField>

                              <FormSectionField>
                                <Field
                                  name={`propertyDetails.parkings.${index}.landRegistryNumber`}
                                  onChange={fieldChangeHandler(handleChange)}
                                  label={intl.formatMessage({
                                    id: "PRODUCT.INFO.LAND_REGISTRY_NUMBER",
                                  })}
                                  component={Input}
                                  disabled={disabled}
                                />
                              </FormSectionField>

                              <FormSectionField>
                                <Select
                                  name={`propertyDetails.parkings.${index}.type`}
                                  value={parking?.type}
                                  onChange={fieldChangeHandler(handleChange)}
                                  disabled={disabled}
                                  customFeedbackLabel={true}
                                  label={<FormattedMessage id={"COMMON.TYPE"} />}
                                >
                                  {[
                                    { label: "PROPERTY.TYPES.INDOOR_PARKING", key: "INDOOR" },
                                    { label: "PROPERTY.TYPES.OUTDOOR_PARKING", key: "OUTDOOR" },
                                  ].map((value, index2) => (
                                    <option key={index2} value={value.key}>
                                      {intl.formatMessage({ id: value.label })}
                                    </option>
                                  ))}
                                </Select>
                              </FormSectionField>

                              <FormSectionField label={<FormattedMessage id={"COMMON.PRICE"} />}>
                                <NumberInput
                                  className="form-control text-right"
                                  suffix={SUFFIX_EURO_CURRENCY}
                                  decimalScale={2}
                                  value={parking?.price ?? 0.0}
                                  onValueChange={numberFieldChangeHandler({
                                    name: `propertyDetails.parkings.${index}.price`,
                                    handleChange,
                                  })}
                                />
                              </FormSectionField>
                            </div>

                            <div className="mx-2 d-flex flex-column justify-content-end">
                              {!disabled && (
                                <button
                                  style={{ minHeight: "36px" }}
                                  className={"btn btn-icon btn-light btn-hover-danger btn-sm"}
                                  onClick={removeParkingButtonClickHandler({
                                    values,
                                    index,
                                    remove,
                                  })}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                                    />
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                          <hr className="my-5 mx-4" />
                        </div>
                      )
                    )}

                    {!disabled && (
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
                          onClick={addParkingButtonClickHandler({ values, push })}
                        >
                          <i className="ki ki-plus icon-nm" />
                          <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </FieldArray>
            </FormSection>
          )}
          {/* @end: parking */}

          {/* @begin: cellar */}
          <FormSection title={<FormattedMessage id={"PRODUCT.INFO.CELLAR"} />}>
            <FormSectionRegistryRow
              {...{
                disabled,
                onFieldChange: fieldChangeHandler(handleChange),
                suffix: {
                  surface: SUFFIX_UNIT_AREA,
                  thousands: SUFFIX_THOUSANDS,
                },

                lot: "propertyDetails.cellarSurface.parcel",
                registry: "propertyDetails.cellarSurface.landRegistryNumber",

                surface: {
                  value: values?.propertyDetails?.cellarSurface?.private ?? 0,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.cellarSurface.private",
                    defaultValue: 0,
                    handleChange,
                  }),
                },

                thousands: {
                  value: values?.propertyDetails?.cellarSurface?.thousands,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.cellarSurface.thousands",
                    handleChange,
                  }),
                },
              }}
            />
          </FormSection>
          {/* @end: cellar */}

          {/* @begin: garden */}
          <FormSection title={<FormattedMessage id={"PRODUCT.INFO.GARDEN"} />}>
            <FormSectionRegistryRow
              {...{
                disabled,
                onFieldChange: fieldChangeHandler(handleChange),
                suffix: {
                  surface: SUFFIX_UNIT_AREA,
                  thousands: SUFFIX_THOUSANDS,
                },

                lot: "propertyDetails.gardenSurface.parcel",
                registry: "propertyDetails.gardenSurface.landRegistryNumber",

                surface: {
                  value: values?.propertyDetails?.gardenSurface?.private ?? 0,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.gardenSurface.private",
                    defaultValue: 0,
                    handleChange,
                  }),
                },

                thousands: {
                  value: values?.propertyDetails?.gardenSurface?.thousands,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.gardenSurface.thousands",
                    handleChange,
                  }),
                },
              }}
            />
          </FormSection>
          {/* @end: garden */}

          {/* @begin: terrace */}
          <FormSection title={<FormattedMessage id={"PRODUCT.INFO.TERRACE"} />}>
            <FormSectionRegistryRow
              {...{
                disabled,
                onFieldChange: fieldChangeHandler(handleChange),
                suffix: {
                  surface: SUFFIX_UNIT_AREA,
                  thousands: SUFFIX_THOUSANDS,
                },

                lot: "propertyDetails.terraceSurface.parcel",
                registry: "propertyDetails.terraceSurface.landRegistryNumber",

                surface: {
                  value: values?.propertyDetails?.terraceSurface?.private ?? 0,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.terraceSurface.private",
                    defaultValue: 0,
                    handleChange,
                  }),
                },

                thousands: {
                  value: values?.propertyDetails?.terraceSurface?.thousands,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.terraceSurface.thousands",
                    handleChange,
                  }),
                },
              }}
            />
          </FormSection>
          {/* @end: terrace */}

          {/* @begin: balcony */}
          <FormSection title={<FormattedMessage id={"PRODUCT.INFO.BALCONY"} />}>
            <FormSectionRegistryRow
              {...{
                disabled,
                onFieldChange: fieldChangeHandler(handleChange),
                suffix: {
                  surface: SUFFIX_UNIT_AREA,
                  thousands: SUFFIX_THOUSANDS,
                },

                lot: "propertyDetails.balconySurface.parcel",
                registry: "propertyDetails.balconySurface.landRegistryNumber",

                surface: {
                  value: values?.propertyDetails?.balconySurface?.private ?? 0,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.balconySurface.private",
                    defaultValue: 0,
                    handleChange,
                  }),
                },

                thousands: {
                  value: values?.propertyDetails?.balconySurface?.thousands,
                  onValueChange: numberFieldChangeHandler({
                    name: "propertyDetails.balconySurface.thousands",
                    handleChange,
                  }),
                },
              }}
            />
          </FormSection>
          {/* @end: balcony */}

          {/* @begin: outdoor surface */}
          <FormSection title={<FormattedMessage id={"PRODUCT.INFO.SURFACE"} />}>
            <div className={"d-flex"}>
              {["COMMON.COMMON", "COMMON.PRIVATE"].map((label, index) => (
                <div key={index} className={"col-6 h5 text-center font-weight-bold mt-5"}>
                  <FormattedMessage id={label} />
                </div>
              ))}
            </div>

            {[
              { label: "PRODUCT.INFO.GARDEN", key: "gardenSurface" },
              { label: "PRODUCT.INFO.TERRACE", key: "terraceSurface" },
              { label: "PRODUCT.INFO.BALCONY", key: "balconySurface" },
            ].map((data) => (
              <div key={`${data.key}`} className={"my-2"}>
                <div>
                  <label className={"w-100 ml-4"}>
                    <FormattedMessage id={data.label} />
                  </label>
                </div>

                <div className={"d-flex"}>
                  {["common", "private"].map((key) => (
                    <div key={`${data.key}_${key}`} className={"col-6"}>
                      <NumberInput
                        className={cn("form-control text-right", {
                          "border-0": key === "private",
                        })}
                        suffix={SUFFIX_UNIT_AREA}
                        decimalScale={2}
                        value={(values as any).propertyDetails?.[data.key]?.[key] ?? 0}
                        onValueChange={numberFieldChangeHandler({
                          name: `propertyDetails.${data.key}.${key}`,
                          defaultValue: 0,
                          handleChange,
                          disabled: data.key === "private",
                        })}
                        disabled={disabled || key === "private"}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className="my-2">
              <div>
                <label className={"w-100 ml-4"}>
                  <FormattedMessage id="PRODUCT.INFO.TOTAL_OUTER_SURFACE" />

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                        <FormattedMessage id="PRODUCT.INFO.GARDEN" /> {" + "}
                        <FormattedMessage id="PRODUCT.INFO.TERRACE" /> {" + "}
                        <FormattedMessage id="PRODUCT.INFO.BALCONY" />
                      </Tooltip>
                    }
                  >
                    <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                    </span>
                  </OverlayTrigger>
                </label>
              </div>

              <div className={"d-flex"}>
                {["common", "private"].map((key) => (
                  <div key={`totalSurface_${key}`} className={"col-6"}>
                    <NumberInput
                      className="form-control text-right font-weight-bold border-0"
                      suffix={SUFFIX_UNIT_AREA}
                      decimalScale={2}
                      value={
                        ((values as any).propertyDetails?.gardenSurface?.[key] ?? 0) +
                        ((values as any).propertyDetails?.terraceSurface?.[key] ?? 0) +
                        ((values as any).propertyDetails?.balconySurface?.[key] ?? 0)
                      }
                      disabled={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          </FormSection>
          {/* @end: outdoor surface */}

          <FormSection
            title={<FormattedMessage id={"PROPERTY.INFO.SPECIFICATION"} />}
            renderDivider={false}
          >
            {/* @begin: specification */}
            <FormSectionRow className="form-group mt-3">
              <TextEditor
                name={"propertyDetails.specification"}
                data={values.propertyDetails.specification!}
                saveMethod={saveProductFields}
                disabled={disabled}
                toolbarOption={commentToolbar}
                data-cy={"input-product-details-specification"}
              />
            </FormSectionRow>
            {/* @end: specification */}

            {/* @begin: performance classes */}
            <FormSectionRow className="form-group form-row d-flex justify-content-around">
              {[
                {
                  label: "PRODUCT.INFO.ENERGY_PERFORMANCE_CLASS",
                  key: "energyPerformanceClass",
                },
                {
                  label: "PRODUCT.INFO.ENVIRONMENTAL_PERFORMANCE_CLASS",
                  key: "environmentalPerformanceClass",
                },
                {
                  label: "PRODUCT.INFO.THERMAL_INSULATION_CLASS",
                  key: "thermalInsulationClass",
                },
              ].map((data, index) => (
                <FormSectionField
                  label={<FormattedMessage id={data.label} />}
                  className={"my-2"}
                  key={index}
                >
                  <div className={"d-flex"}>
                    <ButtonGroup>
                      {["A+", "A", "B", "C", "D", "E", "F", "G", "H", "I"].map((value) => (
                        <Button
                          className={"p-3"}
                          variant={
                            values.propertyDetails[data.key as keyof IPropertyDetails] === value
                              ? "primary"
                              : "outline-secondary"
                          }
                          key={value}
                          disabled={disabled}
                          onClick={buttonClickFieldChangeHandler({
                            name: `propertyDetails.${data.key}`,
                            value,
                          })}
                        >
                          {value}
                        </Button>
                      ))}
                    </ButtonGroup>

                    <ButtonGroup className={"ml-2"}>
                      <Button
                        className={"p-3"}
                        variant={
                          !values.propertyDetails[data.key as keyof IPropertyDetails]
                            ? "primary"
                            : "outline-secondary"
                        }
                        disabled={disabled}
                        onClick={buttonClickFieldChangeHandler({
                          name: `propertyDetails.${data.key}`,
                        })}
                      >
                        N/A
                      </Button>
                    </ButtonGroup>
                  </div>
                </FormSectionField>
              ))}
            </FormSectionRow>
            {/* @end: performance classes */}
          </FormSection>
        </Form>
      )}
    </Formik>
  );
};

export default ProductEditInformationForm;
