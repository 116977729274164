import * as Yup from "yup";
import { DateUtils } from "../_utils/DateUtils";
import { isValidIBANNumber } from "../_utils/formUtils";
import { IntlShape } from "react-intl";
import { PersonalInfoFilloutFormValidationSchema } from "./PersonalInfoFilloutFormValidationSchema";
import { getTranslation } from "./utilsValidation";

export const KycAmlFilloutFormValidationSchema = (intl: IntlShape) =>
  PersonalInfoFilloutFormValidationSchema(intl).shape({
    birthDate: Yup.date()
      .test(
        "age-adult-check",
        getTranslation(intl, "VALIDATION.BIRTH_DATE.TOO_YOUNG"),
        function (value: any) {
          return DateUtils.diffAgeInYears(new Date(value)) >= 18;
        }
      )
      .required(getTranslation(intl, "VALIDATION.BIRTH_DATE")),
    birthPlace: Yup.string()
      .notOneOf(["EMPTY.STRING"], getTranslation(intl, "VALIDATION.BIRTH_PLACE"))
      .required(getTranslation(intl, "VALIDATION.BIRTH_PLACE")),
    tin: Yup.string().required(getTranslation(intl, "VALIDATION.TIN")),
    employmentStatus: Yup.string()
      .notOneOf(["EMPTY.STRING"], getTranslation(intl, "VALIDATION.WORK.EMPLOYMENT_STATUS"))
      .required(getTranslation(intl, "VALIDATION.WORK.EMPLOYMENT_STATUS")),
    employerName: Yup.string().when("employmentStatus", {
      is: (value: any) => value === "EMPLOYMENT.STATUS.EMPLOYEE",
      then: Yup.string().required(getTranslation(intl, "VALIDATION.WORK.EMPLOYER_NAME")),
    }),
    employerSector: Yup.string().when("employmentStatus", {
      is: (value: any) => value === "EMPLOYMENT.STATUS.EMPLOYEE",
      then: Yup.string()
        .notOneOf(["EMPTY.STRING"], getTranslation(intl, "VALIDATION.WORK.EMPLOYER_SECTOR"))
        .required(getTranslation(intl, "VALIDATION.WORK.EMPLOYER_SECTOR")),
    }),
    sourceOfFunds: Yup.string()
      .notOneOf(["EMPTY.STRING"], getTranslation(intl, "VALIDATION.SOURCE_OF_FUNDS"))
      .required(getTranslation(intl, "VALIDATION.SOURCE_OF_FUNDS")),
    iban: Yup.string().test(
      "iban-validation-check",
      getTranslation(intl, "VALIDATION.IBAN"),
      function (value) {
        return isValidIBANNumber(value);
      }
    ),
  });
