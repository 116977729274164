import { Field, FieldArray, FieldArrayRenderProps, FormikHandlers, useFormikContext } from "formik";
import { Input } from "../../../_metronic/_partials/controls";
import { cloneDeep, isEqual, isUndefined, sumBy } from "lodash-es";
import React, { ChangeEvent, FC, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { ReactSortable } from "react-sortablejs";
import { NumberInput } from "../../_utils/formUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomCard, CustomCardLine } from "../Common/CustomCard";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { canEdit, canSeeHiddenLine } from "../../_utils/authUtils";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import cn from "clsx";
import { useBudgetsUIContext } from "./BudgetsUIContext";
import { BudgetLine } from "./components/BudgetLine";
import { DropdownItem } from "../../_components/actions-menu/DropdownItem";
import { useCustomLocationState } from "../../_utils/useCustomLocationState";
import * as projectActions from "../PropertiesManagement/_redux/projects/projectsActions";
import {
  IBudget,
  IBudgetLine,
  IBudgetSortedCategory,
  IFormattedBudgetCategory,
} from "../../../data/schemas";
import { NumberFormatValues } from "react-number-format";

export interface IBudgetEditCategoryProps {
  id: string;
  budget: IBudget;
  displayedDataCategory: IFormattedBudgetCategory;
  category?: IBudgetSortedCategory;
  categoryIndex?: number;
  categoryArrayHelpers?: FieldArrayRenderProps;
  saveBudgetFields?: (key: string | string[], value: any) => void;
  disabled: boolean;
}

export const BudgetEditCategory: FC<IBudgetEditCategoryProps> = ({
  id,
  budget,
  displayedDataCategory,
  category,
  categoryIndex,
  categoryArrayHelpers,
  saveBudgetFields,
  disabled,
}) => {
  const intl = useIntl();
  const { handleChange, values, setFieldValue } = useFormikContext<IBudget>();
  const { columnWidth, triggerExpandCategories, triggerCollapseCategories } = useBudgetsUIContext();
  const { setFromUrlTo } = useCustomLocationState();
  const dispatch = useAppDispatch();

  const { groups, session, project } = useAppSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
    project: state.projects.projectForEdit.current,
  }));

  const handleCategoryChange = (
    e: ChangeEvent<HTMLInputElement>,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].label = e.target.value;
      saveBudgetFields("sortedLines", res);
      handleChange(e);
    }
  };

  const handleLineNameChange = (
    e: ChangeEvent<HTMLInputElement>,
    lineIndex: number,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines) ?? [];
      res[categoryIndex].lines[lineIndex].label = e.target.value;
      saveBudgetFields("sortedLines", res);
      handleChange(e);
    }
  };

  const handleLineAmountChange = (value: NumberFormatValues, lineIndex: number) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].lines[lineIndex]["plannedBudget"] = value.floatValue || 0;
      saveBudgetFields("sortedLines", res);
      setFieldValue("sortedLines", res);
    }
  };

  const removeCategory = (
    e: React.MouseEvent<HTMLButtonElement>,
    categoryArrayHelpers: FieldArrayRenderProps
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      e.preventDefault();
      categoryArrayHelpers.remove(categoryIndex);
      const res = cloneDeep(values.sortedLines);
      res.splice(categoryIndex, 1);
      saveBudgetFields("sortedLines", res);
    }
  };

  const addLine = (
    e: React.MouseEvent<HTMLButtonElement>,
    lineArrayHelpers: FieldArrayRenderProps
  ) => {
    e.preventDefault();
    lineArrayHelpers.push({
      id: uuid(),
      label: "",
      plannedBudget: 0,
    });
  };

  const removeLine = (
    e: React.MouseEvent<HTMLButtonElement>,
    lineArrayHelpers: FieldArrayRenderProps,
    lineIndex: number
  ) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      e.preventDefault();
      lineArrayHelpers.remove(lineIndex);
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].lines.splice(lineIndex, 1);
      saveBudgetFields("sortedLines", res);
    }
  };

  const moveLine = (sortedLines: IBudgetLine[]) => {
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      let res = cloneDeep(values.sortedLines);
      const category = res[categoryIndex];
      category.lines = sortedLines;
      setFieldValue("sortedLines", res);
      res = res.map(({ chosen, selected, ...restCategory }) => ({
        ...restCategory,
        lines: restCategory.lines.map(({ chosen, selected, ...line }) => line),
      }));
      if (!isEqual(res, budget.sortedLines)) {
        saveBudgetFields("sortedLines", res);
      }
    }
  };

  const setCategoryVisibility = (
    e: React.MouseEvent<HTMLButtonElement>,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!!saveBudgetFields && !isUndefined(categoryIndex)) {
      const res = cloneDeep(values.sortedLines);
      res[categoryIndex].visible = !res[categoryIndex].visible;
      saveBudgetFields("sortedLines", res);
      handleChange({ target: { name: "visible", value: res[categoryIndex].visible } });
      // Change real budget in local
      if (project?.subcontractorsFinanceFiles) {
        const newSubcontractorsFinanceFiles = cloneDeep(project.subcontractorsFinanceFiles);
        const catRealBudget =
          (project.subcontractorsFinanceFiles.orders?.data?.[res[categoryIndex].id]?.sum ?? 0) +
          (project.subcontractorsFinanceFiles.supplementary_agreements?.data?.[
            res[categoryIndex].id
          ]?.sum ?? 0);
        newSubcontractorsFinanceFiles.orders.total.public += res[categoryIndex].visible
          ? catRealBudget
          : -catRealBudget;
        dispatch(
          projectActions.updateProjectFieldLocally(
            "subcontractorsFinanceFiles",
            newSubcontractorsFinanceFiles
          )
        );
      }
    }
  };

  const budgetDifference = useMemo(() => {
    if (category) {
      const sffQuoteCategoryTotal =
        (project?.subcontractorsFinanceFiles?.orders?.data?.[category.id]?.sum ?? 0) +
        (project?.subcontractorsFinanceFiles?.supplementary_agreements?.data?.[category.id]?.sum ??
          0);
      return sffQuoteCategoryTotal - sumBy(category?.lines, "plannedBudget");
    }
    return 0;
  }, [project, category]);

  const displayLineFiles = (line: any) => {
    setFromUrlTo({ url: `/budgets/${budget.id}/${id}/${line.id}`, name: budget.name });
  };

  return (
    <CustomCard
      collapsable={true}
      draggable={id !== "unspecifiedCategory" && canEdit(groups, session, "BUDGET")}
      remove={
        canEdit(groups, session, "BUDGET") && id !== "unspecifiedCategory"
          ? (e: React.MouseEvent<HTMLButtonElement>) => removeCategory(e, categoryArrayHelpers!)
          : undefined
      }
      collapse={triggerCollapseCategories}
      expand={triggerExpandCategories}
      headerClassName={`flex-nowrap ${!category?.visible && "bg-info-o-40"}`}
      headerStyle={{ minHeight: "41px" }}
      header={
        <BudgetLine
          columnWidth={columnWidth}
          selectedBudget={!!budget?.id && budget.id === project?.selectedBudget}
          isHeader={true}
          line={{
            label:
              id === "unspecifiedCategory" ? (
                <span className={"pl-3 font-weight-bold"}>{displayedDataCategory.label}</span>
              ) : (
                <>
                  {canSeeHiddenLine(groups, session) && (
                    <button
                      className={"btn btn-icon btn-link-primary"}
                      onClick={(e) => setCategoryVisibility(e, handleChange)}
                    >
                      <span className="svg-icon svg-icon-xl">
                        <SVG
                          src={toAbsoluteUrl(
                            `/media/svg/icons/General/${
                              category?.visible ? "Visible" : "Hidden"
                            }.svg`
                          )}
                        />
                      </span>
                    </button>
                  )}
                  <Field name={`sortedLines.${categoryIndex}.id`} type="hidden" />
                  <Field
                    name={`sortedLines.${categoryIndex}.label`}
                    component={Input}
                    withFeedbackLabel={false}
                    placeholder={intl.formatMessage({
                      id: "BUDGET.CATEGORY.NAME",
                    })}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleCategoryChange(e, handleChange)
                    }
                    disabled={disabled}
                    onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
                    className="py-2"
                    style={{ height: "auto" }}
                  />
                </>
              ),
            planned: displayedDataCategory?.planned,
            quotes: displayedDataCategory?.quotes,
            supplementary_agreements: displayedDataCategory?.supplementary_agreements,
            orders: displayedDataCategory?.orders,
            realDifference: (
              <span
                className={cn(
                  id !== "unspecifiedCategory" &&
                    (budgetDifference <= 0 ? "text-success" : "text-danger")
                )}
              >
                {displayedDataCategory?.realDifference}
              </span>
            ),
            realDifferencePercentage: (
              <span
                className={cn(
                  id !== "unspecifiedCategory" &&
                    (budgetDifference <= 0 ? "text-success" : "text-danger")
                )}
              >
                {displayedDataCategory?.realDifferencePercentage}
              </span>
            ),
            invoiced: displayedDataCategory?.invoiced,
            invoicedPercentage: displayedDataCategory?.invoicedPercentage,
          }}
        />
      }
    >
      <FieldArray
        name={`sortedLines.${categoryIndex}.lines`}
        render={(lineArrayHelpers) => (
          <>
            <ReactSortable
              list={cloneDeep(category?.lines || [])}
              setList={moveLine}
              swapThreshold={0.65}
              animation={150}
              fallbackOnBody={true}
              handle=".line-handle"
              scroll={true}
              bubbleScroll={true}
            >
              {category?.lines?.map((line, lineIndex) => {
                const displayedDataLine = displayedDataCategory?.lines?.[lineIndex];
                const sffLine = Object.values(
                  project?.subcontractorsFinanceFiles?.orders?.data?.[category.id]?.lines ?? {}
                )?.find((l) => l.budgetLineId === line.id);
                const lineBudgetDifference = (sffLine?.amount ?? 0) - line.plannedBudget;
                return (
                  <CustomCardLine
                    draggable={canEdit(groups, session, "BUDGET")}
                    remove={
                      canEdit(groups, session, "BUDGET")
                        ? (e: React.MouseEvent<HTMLButtonElement>) =>
                            removeLine(e, lineArrayHelpers, lineIndex)
                        : undefined
                    }
                    removable={category.lines.length !== 1}
                    key={line.id}
                    actions={
                      budget.id === project?.selectedBudget && (
                        <DropdownItem
                          onClick={() => displayLineFiles(line)}
                          icon={"/media/svg/icons/Files/Selected-file.svg"}
                          title={"BUDGET.LINE.RELATED_FILES"}
                        />
                      )
                    }
                  >
                    <BudgetLine
                      columnWidth={columnWidth}
                      selectedBudget={!!budget.id && budget.id === project?.selectedBudget}
                      line={{
                        label: (
                          <>
                            <Field
                              name={`sortedLines.${categoryIndex}.lines.${lineIndex}.id`}
                              type="hidden"
                            />
                            <Field
                              name={`sortedLines.${categoryIndex}.lines.${lineIndex}.label`}
                              className="form-control-sm"
                              component={Input}
                              withFeedbackLabel={false}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleLineNameChange(e, lineIndex, handleChange)
                              }
                              disabled={disabled}
                            />
                          </>
                        ),
                        planned: (
                          <NumberInput
                            className="form-control form-control-sm text-right"
                            value={line.plannedBudget}
                            suffix={" €"}
                            decimalScale={2}
                            onValueChange={(e: NumberFormatValues) =>
                              handleLineAmountChange(e, lineIndex)
                            }
                            disabled={disabled}
                            size={5}
                          />
                        ),
                        quotes: displayedDataLine?.quotes,
                        supplementary_agreements: displayedDataLine?.supplementary_agreements,
                        orders: displayedDataLine?.orders,
                        realDifference: (
                          <span
                            className={cn(
                              displayedDataLine?.realDifference !== "-" &&
                                (lineBudgetDifference <= 0 ? "text-success" : "text-danger")
                            )}
                          >
                            {displayedDataLine?.realDifference}
                          </span>
                        ),
                        realDifferencePercentage: (
                          <span
                            className={cn(
                              displayedDataLine?.realDifference !== "-" &&
                                (lineBudgetDifference <= 0 ? "text-success" : "text-danger")
                            )}
                          >
                            {displayedDataLine?.realDifferencePercentage}
                          </span>
                        ),
                        invoiced: displayedDataLine?.invoiced,
                        invoicedPercentage: displayedDataLine?.invoicedPercentage,
                      }}
                    />
                  </CustomCardLine>
                );
              })}
            </ReactSortable>
            {budget.id &&
              budget.id === project?.selectedBudget &&
              displayedDataCategory?.unspecifiedLines?.map((line, idx) => {
                return (
                  <CustomCardLine
                    key={idx}
                    actions={
                      budget.id === project?.selectedBudget && (
                        <DropdownItem
                          onClick={() => displayLineFiles(line)}
                          icon={"/media/svg/icons/Files/Selected-file.svg"}
                          title={"BUDGET.LINE.RELATED_FILES"}
                        />
                      )
                    }
                  >
                    <BudgetLine
                      columnWidth={columnWidth}
                      selectedBudget={true}
                      line={{
                        ...line,
                        label: <span className={"pl-3"}>{line.label}</span>,
                      }}
                    />
                  </CustomCardLine>
                );
              })}
            {!disabled && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
                  onClick={(e) => addLine(e, lineArrayHelpers)}
                >
                  <i className="ki ki-plus icon-nm" />
                  <FormattedMessage id="BUDGET.ACTION.ADD.LINE" />
                </button>
              </div>
            )}
          </>
        )}
      />
    </CustomCard>
  );
};
