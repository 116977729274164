import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FieldArray, FieldArrayRenderProps, FormikHelpers, useFormikContext } from "formik";
import { ReactSortable } from "react-sortablejs";
import { BudgetEditCategory } from "./BudgetEditCategory";
import React, { FC, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "../../../redux/hooks";
import { isEqual, max, sumBy } from "lodash-es";
import { useBudgetsUIContext } from "./BudgetsUIContext";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import { useWindowSize } from "../../_utils/useWindowSize";
import { NumberInput } from "../../_utils/formUtils";
import { isAdmin } from "../../_utils/authUtils";
import { shallowEqual } from "react-redux";
import {
  formatBudgetLine,
  formatUnspecifiedLines,
  getSubcontractorFilesCategoryTotals,
  getSubcontractorFilesLine,
} from "./BudgetsUtils";
import {
  BudgetLineColumns,
  IBudget,
  IBudgetSortedCategory,
  IFormattedBudgetCategory,
  SubcontractorFinanceType,
} from "../../../data/schemas";
import ScrollContainer from "react-indiana-drag-scroll";

export interface IBudgetEditExpensesProps {
  saveBudgetFields: (key: string | string[], value: any) => void;
  budget: IBudget;
  disabled: boolean;
}

export const BudgetEditExpenses: FC<IBudgetEditExpensesProps> = ({
  saveBudgetFields,
  budget,
  disabled,
}) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<IBudget>();
  const { columnWidth, setColumnWidth, expandCategories, collapseCategories } =
    useBudgetsUIContext();
  const windowSize = useWindowSize();

  console.log("BUDGET FOR EDIT", budget);

  const addCategory = (
    e: React.MouseEvent<HTMLButtonElement>,
    categoryArrayHelpers: FieldArrayRenderProps
  ) => {
    e.preventDefault();
    categoryArrayHelpers.push({
      id: uuid(),
      label: "",
      visible: true,
      lines: [{ id: uuid(), label: "", plannedBudget: 0 }],
    });
  };

  const moveCategory = (
    setFieldValue: FormikHelpers<IBudget>["setFieldValue"],
    sortedCategories: IBudgetSortedCategory[]
  ) => {
    if (sortedCategories.length) {
      setFieldValue("sortedLines", sortedCategories);
      const res = sortedCategories.map(({ chosen, selected, ...category }) => ({
        ...category,
        lines: category.lines.map(({ chosen, selected, ...line }) => line),
      }));
      if (!isEqual(res, budget.sortedLines)) {
        saveBudgetFields("sortedLines", res);
      }
    }
  };

  const { project, session, groups, budgetLoading } = useAppSelector(
    (state) => ({
      project: state.projects.projectForEdit.current,
      session: state.auth.session,
      groups: state.auth.groups,
      budgetLoading: state.budgets.actionsLoading,
    }),
    shallowEqual
  );

  const [plannedTotal, setPlannedTotal] = useState(0);
  const [publicPlannedTotal, setPublicPlannedTotal] = useState(0);
  const [hiddenPlannedTotal, setHiddenPlannedTotal] = useState<number>();

  useEffect(() => {
    let total = 0;
    let publicTotal = 0;
    let hiddenTotal = 0;
    for (const category of budget.sortedLines ?? []) {
      if (category.visible) {
        publicTotal += sumBy(category.lines, "plannedBudget");
      } else {
        hiddenTotal += sumBy(category.lines, "plannedBudget");
      }
      total += sumBy(category.lines, "plannedBudget");
    }
    setPlannedTotal(total);
    if (isAdmin(groups, session)) {
      setPublicPlannedTotal(publicTotal);
      setHiddenPlannedTotal(hiddenTotal);
    }
  }, [budget, groups, session]);

  const [textWidthCoef, setTextWidthCoef] = useState<{
    headers: number;
    categoryHeaders: number;
    lines: number;
  }>({
    headers: 5.8,
    categoryHeaders: 6.3,
    lines: 5.7,
  });

  useEffect(() => {
    /**
     * These numbers are used in order to predict the size (in px) an element will have from the number of characters it has before render
     * Those numbers are manually written, they may have to change if we change the font or the font-size
     * e.g. "1,000 €" -> 7 chars -> 7 * 5.7 = 39.9px
     */
    let newWidthCoef;
    if (KTUtil.isBreakpointUp("xl")) {
      newWidthCoef = { headers: 6.25, categoryHeaders: 6.5, lines: 5.7 };
    } else if (KTUtil.isBreakpointUp("lg")) {
      newWidthCoef = { headers: 6.03, categoryHeaders: 6.5, lines: 5.7 };
    } else {
      newWidthCoef = {
        headers: 5.8,
        categoryHeaders: 6.3,
        lines: 5.7,
      };
    }
    if (!isEqual(textWidthCoef, newWidthCoef)) {
      setTextWidthCoef(newWidthCoef);
    }
  }, windowSize);

  const headers: Record<BudgetLineColumns, string> = useMemo(
    () => ({
      planned: intl.formatMessage({ id: "BUDGET.PLANNED" }),
      quotes: intl.formatMessage({ id: "FINANCE.QUOTES" }),
      supplementary_agreements: intl.formatMessage({ id: "BUDGET.SUPPLEMENTARY_AGREEMENTS" }),
      orders: intl.formatMessage({ id: "FINANCE.ORDERS" }),
      realDifference: intl.formatMessage({ id: "BUDGET.DIFFERENCE" }),
      realDifferencePercentage: intl.formatMessage({ id: "BUDGET.DIFFERENCE_PERCENT" }),
      invoiced: intl.formatMessage({ id: "BUDGET.INVOICED" }),
      invoicedPercentage: intl.formatMessage({ id: "BUDGET.INVOICED_PERCENT" }),
    }),
    [intl]
  );
  const [displayedData, setDisplayedData] = useState<Record<string, IFormattedBudgetCategory>>();

  useEffect(() => {
    if (budget) {
      // Create an object before render with all readonly values in order to calculate the width of the largest object in each column
      const hasUnspecifiedCategory = Object.values(SubcontractorFinanceType).some(
        (financeType) =>
          !!project?.subcontractorsFinanceFiles?.[financeType]?.data?.unspecifiedCategory
      );
      const displayedDataRes = {
        ...budget.sortedLines?.reduce(
          (acc, category) => ({
            ...acc,
            [category.id]: {
              ...formatBudgetLine({
                planned: sumBy(category.lines, "plannedBudget"),
                ...getSubcontractorFilesCategoryTotals(
                  project?.subcontractorsFinanceFiles,
                  category.id
                ),
                intl,
              }),
              lines: [
                ...category.lines.map((line) =>
                  formatBudgetLine({
                    planned: line.plannedBudget,
                    ...getSubcontractorFilesLine(
                      project?.subcontractorsFinanceFiles,
                      category.id,
                      line.id
                    ),
                    intl,
                  })
                ),
              ],
              unspecifiedLines: formatUnspecifiedLines(
                project?.subcontractorsFinanceFiles,
                category.id,
                intl
              ),
            },
          }),
          {}
        ),
        ...(hasUnspecifiedCategory
          ? {
              unspecifiedCategory: {
                ...formatBudgetLine({
                  ...getSubcontractorFilesCategoryTotals(
                    project?.subcontractorsFinanceFiles,
                    "unspecifiedCategory"
                  ),
                  intl,
                  label: <FormattedMessage id="BUDGET.NOT_SPECIFIED" />,
                }),
                lines: [],
                unspecifiedLines: formatUnspecifiedLines(
                  project?.subcontractorsFinanceFiles,
                  "unspecifiedCategory",
                  intl
                ),
              },
            }
          : {}),
      };
      setDisplayedData(displayedDataRes);
    }
    return () => {
      setDisplayedData(undefined);
    };
  }, [project, budget]);

  useEffect(() => {
    /**
     * When all data is ready we set the column width with the biggest element we have for each column.
     */
    if (displayedData) {
      const textsLengths = Object.values(BudgetLineColumns).reduce(
        (acc, column) => ({ ...acc, [column]: [headers[column].length * textWidthCoef.headers] }),
        {} as Record<BudgetLineColumns, number[]>
      );
      textsLengths[BudgetLineColumns.PLANNED].push(100); // 100 is for the minimum input size of planned line
      Object.values(displayedData).forEach((category) => {
        Object.values(BudgetLineColumns).forEach((column) =>
          textsLengths[column].push(category[column].length * textWidthCoef.categoryHeaders)
        );
        category.lines.forEach((line) => {
          Object.values(BudgetLineColumns).forEach((column) =>
            textsLengths[column].push(line[column].length * textWidthCoef.lines)
          );
        });
      });
      setColumnWidth(
        Object.values(BudgetLineColumns).reduce(
          (acc, column) => ({ ...acc, [column]: max(textsLengths[column]) ?? 0 }),
          {} as Record<BudgetLineColumns, number>
        )
      );
    }
  }, [displayedData, textWidthCoef]);

  return (
    <>
      <div className={"d-sm-flex flex-wrap"}>
        <div className={"mb-4 card card-custom bg-light mr-sm-8"}>
          <div className="card-body d-flex flex-column justify-content-center px-10 py-6">
            <div className={`text-inverse-light font-weight-bolder font-size-h2`}>
              <NumberInput
                value={plannedTotal}
                displayType="text"
                suffix={" €"}
                decimalScale={2}
                className="ml-auto font-weight-bold"
              />
            </div>
            {isAdmin(groups, session) && (
              <>
                <div className="row font-size-xs text-inverse-light">
                  <div className={"col"}>
                    <FormattedMessage id="COMMON.PUBLIC" />:
                  </div>
                  <div className={"col-auto"}>
                    <NumberInput
                      displayType="text"
                      value={publicPlannedTotal}
                      decimalScale={2}
                      suffix={" €"}
                      className="font-weight-bold text-right"
                    />
                  </div>
                </div>
                <div className="mb-4 row font-size-xs text-inverse-light">
                  <div className={"col"}>
                    <FormattedMessage id="COMMON.HIDDEN" />:
                  </div>
                  <div className={"col-auto"}>
                    <NumberInput
                      displayType="text"
                      value={hiddenPlannedTotal}
                      decimalScale={2}
                      suffix={" €"}
                      className="font-weight-bold text-right"
                    />
                  </div>
                </div>
              </>
            )}
            <div className={`text-inverse-light font-weight-bold font-size-lg mt-1`}>
              <FormattedMessage id="BUDGET.PLANNED" />
            </div>
          </div>
        </div>
        {budget.id && project?.selectedBudget === budget.id && project?.subcontractorsFinanceFiles && (
          <div className={"mb-4 card card-custom bg-light"}>
            <div className="card-body d-flex flex-column justify-content-center px-10 py-6">
              <div className={`text-inverse-light font-weight-bolder font-size-h2`}>
                <NumberInput
                  value={
                    isAdmin(groups, session)
                      ? project.subcontractorsFinanceFiles.orders.total.general +
                        project.subcontractorsFinanceFiles.supplementary_agreements.total.general
                      : project.subcontractorsFinanceFiles.orders.total.public +
                        project.subcontractorsFinanceFiles.supplementary_agreements.total.public
                  }
                  displayType="text"
                  suffix={" €"}
                  decimalScale={2}
                  className="ml-auto font-weight-bold"
                />
              </div>
              {isAdmin(groups, session) && (
                <>
                  <div className="row font-size-xs text-inverse-light">
                    <div className={"col"}>
                      <FormattedMessage id="COMMON.PUBLIC" />:
                    </div>
                    <div className={"col-auto"}>
                      <NumberInput
                        displayType="text"
                        value={
                          project.subcontractorsFinanceFiles.orders.total.public +
                          project.subcontractorsFinanceFiles.supplementary_agreements.total.public
                        }
                        decimalScale={2}
                        suffix={" €"}
                        className="font-weight-bold text-right"
                      />
                    </div>
                  </div>
                  <div className="mb-4 row font-size-xs text-inverse-light">
                    <div className={"col"}>
                      <FormattedMessage id="COMMON.HIDDEN" />:
                    </div>
                    <div className={"col-auto"}>
                      <NumberInput
                        displayType="text"
                        value={
                          project.subcontractorsFinanceFiles.orders.total.general +
                          project.subcontractorsFinanceFiles.supplementary_agreements.total
                            .general -
                          (project.subcontractorsFinanceFiles.orders.total.public +
                            project.subcontractorsFinanceFiles.supplementary_agreements.total
                              .public)
                        }
                        decimalScale={2}
                        suffix={" €"}
                        className="font-weight-bold text-right"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className={`text-inverse-light font-weight-bold font-size-lg mt-1`}>
                <FormattedMessage id="BUDGET.REAL" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mb-4">
        <OverlayTrigger
          overlay={
            <Tooltip id="budgets-expand-tooltip">
              <FormattedMessage id="TOOLTIP.EXPAND_ALL" />
            </Tooltip>
          }
        >
          <button
            type="button"
            className="btn btn-icon btn-light-secondary mr-3"
            onClick={expandCategories}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Size.svg")} />
            </span>
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip id="budgets-collapse-tooltip">
              <FormattedMessage id="TOOLTIP.COLLAPSE_ALL" />
            </Tooltip>
          }
        >
          <button
            type="button"
            className="btn btn-icon btn-light-secondary"
            onClick={collapseCategories}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Scale.svg")} />
            </span>
          </button>
        </OverlayTrigger>
      </div>
      {budget && displayedData && ((!budget.id && !budgetLoading) || !!project) ? (
        <ScrollContainer
          hideScrollbars={false}
          ignoreElements={"input"}
          className={"form-group d-flex pr-4"}
          style={{ fontFamily: "Roboto" }}
        >
          <div className={"flex-grow-1"}>
            <div className="d-flex" style={{ fontFamily: "'Roboto Mono', monospace" }}>
              <div className="w-25px" />
              <div
                className="d-flex flex-grow-1 pr-4 pl-1 pb-2 font-size-xs font-weight-bold line-height-sm"
                style={{ color: "#6a6a6a" }}
              >
                <div className="flex-shrink-0">
                  <div className="w-200px w-sm-250px w-md-300px w-lg-350px" />
                </div>
                <div
                  className={"px-2 col text-right"}
                  style={{ minWidth: columnWidth?.planned, boxSizing: "content-box" }}
                >
                  {headers.planned}
                </div>
                {budget.id && budget.id === project?.selectedBudget && (
                  <>
                    <div
                      className={"px-2 col text-right"}
                      style={{ minWidth: columnWidth.quotes, boxSizing: "content-box" }}
                    >
                      {headers.quotes}
                    </div>
                    <div
                      className={"px-2 col text-right"}
                      style={{
                        minWidth: columnWidth.supplementary_agreements,
                        boxSizing: "content-box",
                      }}
                    >
                      {headers.supplementary_agreements}
                    </div>
                    <div
                      className={"px-2 col text-right"}
                      style={{
                        minWidth: columnWidth.orders,
                        boxSizing: "content-box",
                      }}
                    >
                      {headers.orders}
                    </div>
                    <div
                      className={"px-2 col text-right"}
                      style={{ minWidth: columnWidth?.realDifference, boxSizing: "content-box" }}
                    >
                      {headers.realDifference}
                    </div>
                    <div
                      className={"px-2 col text-right"}
                      style={{
                        minWidth: columnWidth?.realDifferencePercentage,
                        boxSizing: "content-box",
                      }}
                    >
                      {headers.realDifferencePercentage}
                    </div>
                    <div
                      className={"px-2 col text-right"}
                      style={{ minWidth: columnWidth?.invoiced, boxSizing: "content-box" }}
                    >
                      {headers.invoiced}
                    </div>
                    <div
                      className={"px-2 col text-right"}
                      style={{
                        minWidth: columnWidth?.invoicedPercentage,
                        boxSizing: "content-box",
                      }}
                    >
                      {headers.invoicedPercentage}
                    </div>
                  </>
                )}
              </div>
              <div className="w-30px ml-5" />
            </div>
            <FieldArray
              name="sortedLines"
              validateOnChange={false}
              render={(categoryArrayHelpers) => (
                <>
                  <ReactSortable
                    list={values.sortedLines ?? []}
                    setList={(sortedCategories) => moveCategory(setFieldValue, sortedCategories)}
                    animation={150}
                    handle=".card-handle"
                    scroll={true}
                    bubbleScroll={true}
                  >
                    {values.sortedLines?.map((category, categoryIndex) => (
                      <BudgetEditCategory
                        id={category.id}
                        budget={budget}
                        displayedDataCategory={displayedData[category.id]}
                        category={category}
                        categoryIndex={categoryIndex}
                        categoryArrayHelpers={categoryArrayHelpers}
                        saveBudgetFields={saveBudgetFields}
                        key={category.id}
                        disabled={disabled}
                      />
                    ))}
                  </ReactSortable>
                  {budget.id &&
                    budget.id === project?.selectedBudget &&
                    displayedData.unspecifiedCategory && (
                      <BudgetEditCategory
                        budget={budget}
                        id={"unspecifiedCategory"}
                        displayedDataCategory={displayedData["unspecifiedCategory"]}
                        disabled={true}
                      />
                    )}
                  {!disabled && (
                    <button
                      type="button"
                      className="btn btn-light w-100 mt-2 d-flex align-items-center justify-content-center"
                      onClick={(e) => addCategory(e, categoryArrayHelpers)}
                    >
                      <i className="ki ki-plus icon-nm" />
                      <FormattedMessage id="BUDGET.ACTION.CATEGORY.ADD" />
                    </button>
                  )}
                </>
              )}
            />
          </div>
        </ScrollContainer>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
        </div>
      )}
    </>
  );
};
