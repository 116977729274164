import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import KTCard from "../../../_metronic/_assets/js/components/card";
import { v4 as uuid } from "uuid";
import "./CustomCard.scss";
import { Overlay, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import cn from "clsx";
import { DropdownMenu } from "../../_components/actions-menu/DropdownMenu";
import { DropdownItem } from "../../_components/actions-menu/DropdownItem";

export interface CustomCardProps {
  header: ReactNode;
  expand?: boolean;
  collapse?: boolean;
  collapsable?: boolean;
  remove?: Function;
  draggable?: boolean;
  headerClassName?: string;
  headerStyle?: React.CSSProperties;
}
export const CustomCard: FC<CustomCardProps> = ({
  header,
  children,
  expand,
  collapse,
  collapsable,
  remove,
  draggable,
  headerClassName,
  headerStyle,
}) => {
  const [id] = useState<string>(uuid());
  const [card, setCard] = useState<InstanceType<typeof KTCard>>();
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const targetRemove = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!card) {
      setCard(new KTCard(id, { toggleSpeed: 200 }));
    }
  }, []);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  useEffect(() => {
    if (card) {
      setIsExpanded(true);
      card.expand();
    }
  }, [expand]);
  useEffect(() => {
    if (card) {
      setIsExpanded(false);
      card.collapse();
    }
  }, [collapse]);

  const toggleCard = (e: React.MouseEvent<HTMLDivElement>) => {
    if (collapsable && card) {
      e.preventDefault();
      setIsExpanded(!isExpanded);
      card.toggle();
    }
  };

  return (
    <div
      className={cn(
        "card card-custom card-border mb-4 position-relative",
        remove && "removable-card"
      )}
      data-card="true"
      id={id}
    >
      {typeof remove !== "undefined" && (
        <>
          <button
            type="button"
            className={`remove-card ${
              showRemove && "remove-card--active"
            } btn btn-icon btn-xs btn-light-danger btn-circle position-absolute top-0 right-0 invisible`}
            style={{ transform: "translate(50%,-50%)" }}
            tabIndex={-1}
            onClick={(e) => setShowRemove(!showRemove)}
            ref={targetRemove}
          >
            <span className="svg-icon line-height-0">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </button>
          {deletePopover({
            showRemove,
            targetRemove: targetRemove.current!,
            remove,
            setShowRemove,
            title: "BUDGET.CATEGORY.DELETE",
          })}
        </>
      )}
      <div
        className={cn(
          "card-header bg-primary-o-40 pr-4 align-items-center",
          draggable ? "pl-2" : "pl-4",
          headerClassName
        )}
        onClick={toggleCard}
        style={{
          cursor: collapsable ? "pointer" : "default",
          minHeight: "50px",
          transition: "all .3s ease",
          ...headerStyle,
        }}
      >
        {draggable && (
          <button
            type="button"
            className="btn btn-icon btn-xs mr-2 card-handle w-15px position-relative"
            onClick={(e) => e.stopPropagation()}
            tabIndex={-1}
          >
            <i className="ki ki-more-ver position-absolute" style={{ left: "-1px" }} />
            <i className="ki ki-more-ver position-absolute" style={{ left: "3px" }} />
          </button>
        )}
        {header}
        {collapsable && (
          <button type="button" className="btn btn-icon btn-link-primary btn-sm ml-4" tabIndex={-1}>
            <i
              className="ki ki-arrow-down icon-nm"
              style={{
                transform: !isExpanded ? "rotate(-90deg)" : "",
                transition: "transform .2s ease",
              }}
            />
          </button>
        )}
      </div>
      {!!children && <div className="card-body p-0">{children}</div>}
    </div>
  );
};

export interface CustomCardLineProps {
  draggable?: boolean;
  remove?: Function;
  removable?: boolean;
  customClasses?: string;
  actions?: ReactNode;
}
export const CustomCardLine: FC<CustomCardLineProps> = ({
  children,
  draggable,
  remove,
  removable,
  customClasses,
  actions,
  ...props
}) => {
  const [showRemove, setShowRemove] = useState(false);
  const targetRemove = useRef<HTMLButtonElement>(null);

  return (
    <div
      {...props}
      className={cn(
        "bg-white border-bottom pr-4 py-2 d-flex align-items-center pl-2",
        draggable && "draggable-line",
        customClasses
      )}
    >
      {draggable ? (
        <button
          type="button"
          className="btn btn-icon btn-xs mr-2 line-handle w-15px position-relative"
          tabIndex={-1}
        >
          <i className="ki ki-more-ver position-absolute" style={{ left: "-1px" }} />
          <i className="ki ki-more-ver position-absolute" style={{ left: "3px" }} />
        </button>
      ) : (
        <div className={"w-15px mr-2"}></div>
      )}
      {children}
      {!!actions || remove ? (
        <DropdownMenu toggleClass={"ml-4"} buttonRef={targetRemove}>
          {actions}
          {typeof remove !== "undefined" && (
            <>
              <DropdownItem
                onClick={() => setShowRemove(!showRemove)}
                icon={"/media/svg/icons/General/Trash.svg"}
                title={"COMMON.DELETE"}
                classes={{ container: "text-danger", svg: "svg-icon-danger" }}
              />
              {deletePopover({
                showRemove,
                targetRemove: targetRemove.current!,
                remove,
                setShowRemove,
                title: "BUDGET.LINE.DELETE",
              })}
            </>
          )}
        </DropdownMenu>
      ) : (
        <div className={"btn btn-clean btn-hover-light-primary btn-sm btn-icon ml-4"}>
          <span className={"svg-icon svg-icon-md cursor-pointer"}></span>
        </div>
      )}
    </div>
  );
};

export interface DeletePopoverProps {
  showRemove: boolean;
  setShowRemove: React.Dispatch<React.SetStateAction<boolean>>;
  remove: Function;
  targetRemove: HTMLButtonElement;
  title: string;
}
export const deletePopover: FC<DeletePopoverProps> = ({
  showRemove,
  setShowRemove,
  remove,
  targetRemove,
  title,
}) => (
  <Overlay
    show={showRemove}
    target={targetRemove}
    placement="top"
    rootClose
    onHide={() => setShowRemove(false)}
  >
    <Popover id="popover-contained">
      <Popover.Title as="h3">
        <FormattedMessage id={title} />
      </Popover.Title>
      <Popover.Content>
        <div className={"d-flex justify-content-end"}>
          <button
            type="button"
            onClick={() => setShowRemove(false)}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="COMMON.ACTION.CANCEL" />
          </button>
          <button
            type="button"
            onClick={(e) => {
              setShowRemove(false);
              remove(e);
            }}
            className="btn btn-delete btn-danger btn-elevate ml-4"
          >
            <FormattedMessage id="COMMON.ACTION.DELETE" />
          </button>
        </div>
      </Popover.Content>
    </Popover>
  </Overlay>
);
