import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import { Field } from "formik";

import { Input } from "_metronic/_partials/controls";

import { NumberInput } from "app/_utils/formUtils";

import { FormSectionRow, FormSectionRowProps } from "../FormSectionRow";
import { FormSectionField } from "../FormSectionField";

//----------------------------------------------------------------------------//

const NUMBER_INPUT_CLASSNAME = "form-control text-right";

interface IInputField extends HTMLElement {
  name: string;
  value: any;
}

export interface NumberInputProps {
  value?: number | string | null;
  onValueChange: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
}

export interface NumberInputSuffix {
  surface?: string;
  thousands?: string;
}

export interface FormSectionRegistryRowProps extends FormSectionRowProps {
  disabled?: boolean;

  /** lot field name */
  lot: string;

  /** registry field name */
  registry: string;

  /** lot and registry change handler */
  onFieldChange: <T extends IInputField>(e: React.ChangeEvent<T>) => void;

  surface: NumberInputProps;
  thousands: NumberInputProps;
  suffix?: NumberInputSuffix;
}

export const FormSectionRegistryRow: React.FunctionComponent<FormSectionRegistryRowProps> = ({
  disabled = false,

  surface,
  lot,
  onFieldChange,

  registry,
  thousands,

  suffix = {
    surface: undefined,
    thousands: undefined,
  },
}) => {
  const intl = useIntl();

  return (
    <FormSectionRow>
      <FormSectionField label={<FormattedMessage id={"PRODUCT.INFO.SURFACE"} />}>
        <NumberInput
          {...surface}
          className={NUMBER_INPUT_CLASSNAME}
          suffix={suffix.surface}
          decimalScale={2}
          disabled={disabled}
        />
      </FormSectionField>

      <FormSectionField>
        <Field
          name={lot}
          onChange={onFieldChange}
          label={intl.formatMessage({
            id: "PRODUCT.INFO.LOT",
          })}
          component={Input}
          disabled={disabled}
        />
      </FormSectionField>

      <FormSectionField>
        <Field
          name={registry}
          onChange={onFieldChange}
          label={intl.formatMessage({
            id: "PRODUCT.INFO.LAND_REGISTRY_NUMBER",
          })}
          component={Input}
          disabled={disabled}
        />
      </FormSectionField>

      <FormSectionField label={<FormattedMessage id={"PRODUCT.INFO.THOUSANDS"} />}>
        <NumberInput
          {...thousands}
          className={NUMBER_INPUT_CLASSNAME}
          suffix={suffix.thousands}
          disabled={disabled}
        />
      </FormSectionField>
    </FormSectionRow>
  );
};

export default FormSectionRegistryRow;
