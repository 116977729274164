import * as React from "react";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { cloneDeep, isEqual, toNumber } from "lodash-es";
import { useDidUpdate } from "rooks";

import { useAppSelector } from "redux/hooks";

import { Card } from "_metronic/_partials/controls";

import { IUser } from "data/schemas";

import { DateUtils } from "app/_utils/DateUtils";
import { isAdmin, canReadAll } from "app/_utils/authUtils";
import { USER } from "app/_utils/userTypes";
import { formatDisplayNameMeIntl } from "app/_utils/userUtils";

import { ProgressBarFormatter } from "app/_components/column-formatters";
import { ImageCropper } from "app/_components/ImageCropper";
import { WidgetUserListAccess } from "app/_components/WidgetUserListAccess";
import { CustomSelect } from "app/_components/CustomSelect";

import * as actions from "app/modules/PropertiesManagement/_redux/projects/projectsActions";
import * as tasksActions from "app/modules/PropertiesManagement/_redux/tasks/tasksActions";

import { ProjectWidget } from "../project-widget/ProjectWidget";
import cn from "clsx";

export interface ProjectDashboardProps {
  tab: string;
  readOnly?: boolean;
}

export const ProjectDashboard: React.FunctionComponent<ProjectDashboardProps> = ({
  tab,
  readOnly = false,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    projectOwnerAssignee,
    projectOwnerAssignees,
    projectForEdit,
    tasks,
    isLoading,
    isLoadingTasks,
    groups,
    session,
    budget,
  } = useAppSelector((state) => {
    const { projects, tasks, auth } = state;
    const { actionsLoading, projectForEdit, projectOwnerAssignees = [] } = projects;

    const currentProject = projectForEdit?.current;
    const selectedBudget = currentProject?.selectedBudget;

    const { groups, session } = auth;
    const { listLoading: isLoadingTasks, entities: tasksList } = tasks;

    return {
      isLoading: actionsLoading,
      projectForEdit: currentProject,
      projectOwnerAssignee: currentProject?.projectOwner,
      projectOwnerAssignees,
      tasks: tasksList,
      isLoadingTasks,
      groups,
      session,
      budget: currentProject?.budgets?.find((budget) => budget.id === selectedBudget),
    };
  }, shallowEqual);

  const [totalTasks, setTotalTasks] = useState<
    { relatedTo: string; status: string }[] | undefined
  >();

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [showCropper, setShowCropper] = useState(false);
  const [avatar, setAvatar] = useState<string | undefined>();
  const [avatarIdentityId, setAvatarIdentityId] = useState<string | undefined>();
  const [totalDelay, setTotalDelay] = useState<number>(0);

  useEffect(() => {
    dispatch(tasksActions.fetchTasks({ relatedTo: `PROJECT#${projectForEdit?.id}` }));
  }, [projectForEdit?.tasks]);

  useEffect(() => {
    if (!isLoadingTasks && tasks) {
      setTotalTasks(
        tasks?.filter(
          (task: { relatedTo: string }) => task?.relatedTo === `PROJECT#${projectForEdit?.id}`
        )
      );
    }
  }, [tasks]);

  useEffect(() => {
    if (!isLoading && tasks !== undefined) {
      setTotalTasks(cloneDeep(tasks));
    }
  }, [isLoading, tasks]);

  useEffect(() => {
    if (
      projectForEdit?.id &&
      projectForEdit.pictureSetup &&
      (!avatarIdentityId || projectForEdit.id !== avatarIdentityId)
    ) {
      setAvatar(projectForEdit.pictureUrl);
      setAvatarIdentityId(projectForEdit.id);
    }
  }, [projectForEdit, avatarIdentityId]);

  useEffect(() => {
    setTotalDelay(0);
    !!tasks && tasks
      .filter((task) =>
        (!!task["dueDate"] && !!task["doneDate"])
          ? new Date(task["dueDate"]) < new Date(task["doneDate"])
          : new Date(task["dueDate"]) < new Date()
      )
      .forEach((task) => {
        const diff = DateUtils.diffBetweenDates(new Date(), new Date(task["dueDate"]));
        setTotalDelay((totalDelay) => totalDelay + toNumber(diff));
      });
  }, [projectForEdit?.tasks, tasks]);

  const saveCroppedImage = (newCroppedImage: any) => {
    if (projectForEdit?.id) {
      const avatar = {
        image: newCroppedImage,
        projectId: projectForEdit.id,
        fileName: selectedFile?.name,
      };

      actions
        .setProjectAvatar(avatar)(dispatch)
        .then((response: { key: string }) => {
          setAvatar(URL.createObjectURL(newCroppedImage));
          dispatch(
            actions.updateProject({
              ...projectForEdit,
              pictureSetup: true,
              pictureKey: response.key,
            })
          );
        });

      setShowCropper(false);
    }
  };

  const updateCurrentImage = (e: any) => {
    const newFile: File = e?.dataTransfer?.files
      ? e.dataTransfer.files[0]
      : e.target && e.target.files
        ? e.target.files[0]
        : null;

    e.target.value = null;

    if (!isEqual(newFile, selectedFile)) {
      setSelectedFile(newFile);
      if (!!newFile) {
        setShowCropper(true);
      }
    }
  };

  const [projectOwnerAssigneesLoading, setProjectOwnerAssigneesLoading] = useState(false);
  const openProjectOwnerAssigneesSelect = () => {
    if (!projectOwnerAssignees?.length) {
      setProjectOwnerAssigneesLoading(true);
      actions
        .fetchProjectOwnerAssignees(intl, { userTypes: [USER] })(dispatch)
        .then(() => setProjectOwnerAssigneesLoading(false));
    }
  };

  const formatUserDisplayName = (user?: IUser, defaultValue = "") =>
    user ? formatDisplayNameMeIntl(intl, user, session.id) : defaultValue;

  const formatProjectOwnerAssignee = (user?: IUser) => {
    if (!user) return null;
    return {
      label: formatUserDisplayName(user),
      value: user,
    };
  };

  const [projectOwnerSelected, setProjectOwnerSelected] = useState(
    formatProjectOwnerAssignee(projectOwnerAssignee)
  );
  useDidUpdate(() => {
    setProjectOwnerSelected(formatProjectOwnerAssignee(projectOwnerAssignee));
  }, [projectOwnerAssignee]);

  const saveProjectOwner = (user?: IUser) => {
    setProjectOwnerSelected(formatProjectOwnerAssignee(user));
    actions.updateProjectOwnerLocally(user)(dispatch);
  };

  const badgeStyle = "btn btn-sm btn-text text-uppercase font-weight-bold d-flex justify-content-center";

  return (
    <Card>
      {!!selectedFile && (
        <ImageCropper
          isOpen={showCropper}
          image={URL.createObjectURL(selectedFile)}
          saveCroppedImage={saveCroppedImage}
          onCancel={() => setShowCropper(false)}
        />
      )}

      <div className="card-body">
        <div className="d-flex">
          <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div className="symbol symbol-60 symbol-md-80 symbol-lg-120">
              <div className="image-input image-input-outline">
                {!!avatar ? (
                  <img className="image-input-wrapper" src={avatar} alt="Project avatar" />
                ) : (
                  <span className="symbol symbol-label font-weight-bold image-input-wrapper">
                    <i className="fas fa-layer-group text-info icon-xl" />
                  </span>
                )}

                {isAdmin(groups, session) && !!projectForEdit && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="change-avatar">
                        <FormattedMessage id="PICTURE.CHANGE" />
                      </Tooltip>
                    }
                  >
                    <label
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="change"
                      data-toggle="tooltip"
                    >
                      <i className="fa fa-pen icon-sm text-muted" />
                      <input
                        type="file"
                        className="d-none"
                        accept=".png, .jpg, .jpeg"
                        onChange={updateCurrentImage}
                      />
                    </label>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="mr-3">
                <div className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">
                  {!!projectForEdit?.name ? projectForEdit?.name : "-"}
                </div>

                {projectForEdit?.address?.city && (
                  <div className="d-flex flex-wrap my-2">
                    <div className="text-muted font-weight-bold">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <i className={"fas fa-map-marker-alt icon-md"} />
                      </span>
                      {projectForEdit?.address?.city}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-sm-flex flex-wrap py-2 align-items-start justify-content-between">
              <div className="d-sm-flex flex-wrap align-items-center">
                <div className="d-sm-flex align-items-center justify-content-between mr-8 mb-4">
                  <div className="m-2">
                    <div className="font-weight-bold mb-2">
                      <FormattedMessage id={"COMMON.START.DATE"} />
                    </div>
                    <span className={`${badgeStyle} btn-light-primary`}>
                      {!!projectForEdit?.startDate
                        ? DateUtils.format(new Date(projectForEdit?.startDate), intl, false)
                        : "-"}
                    </span>
                  </div>

                  <div className="m-2">
                    <div className="font-weight-bold mb-2">
                      <FormattedMessage id={"PROJECT.INFO.AVAILABILITY_DATE"} />
                    </div>
                    <span className={`${badgeStyle} btn-light-facebook`}>
                      {!!projectForEdit?.availability
                        ? DateUtils.format(projectForEdit?.availability, intl, false)
                        : "-"}
                    </span>
                  </div>

                  {!!projectForEdit?.startDate && !!projectForEdit?.availability && (
                    <div className="m-2">
                      <div className="font-weight-bold mb-2">
                        <FormattedMessage id={"PROJECT.INFO.DURATION"} />
                      </div>
                      <span className={`${badgeStyle} btn-light-primary`}>
                        {DateUtils.diffBetweenDates(
                          new Date(projectForEdit?.startDate),
                          new Date(projectForEdit?.availability),
                          intl
                        )}
                      </span>
                    </div>
                  )}

                  {!!projectForEdit?.availability && new Date(projectForEdit?.availability) > new Date(Date.now()) && (
                    <div className="m-2">
                      <div className="font-weight-bold mb-2">
                        <FormattedMessage id={"PROJECT.INFO.TIME_LEFT"} />
                      </div>
                      <span className={`${badgeStyle} btn-light-primary`}>
                        {DateUtils.diffBetweenDates(
                          new Date(new Date().setHours(0, 0, 0, 0)),
                          new Date(projectForEdit?.availability),
                          intl
                        )}
                      </span>
                    </div>
                  )}

                  <div className="m-2">
                    <div className="font-weight-bold mb-2">
                      <FormattedMessage id={"PROJECT.INFO.DELAY"} />
                    </div>
                    <span className={cn(badgeStyle, totalDelay > 0 ? "btn-light-danger" : "btn-light-success")}>
                      {totalDelay > 0 ? intl.formatMessage({ id: "TIMEAGO.LEFT.DAY" }, { time: totalDelay }) : intl.formatMessage({ id: "COMMON.ON_TIME" })}
                    </span>
                  </div>
                </div>
              </div>

              {!!totalTasks && totalTasks.length > 0 && (
                <div className="flex-grow-1 flex-shrink-0 min-w-250px max-w-250px mb-4">
                  <div className="font-weight-bold m-2">
                    <FormattedMessage id="COMMON.PROGRESS" />
                  </div>
                  <div className="m-2" style={{ width: "80%" }}>
                    {ProgressBarFormatter(
                      undefined,
                      projectForEdit,
                      totalTasks.filter((task) => task.status === "DONE").length,
                      totalTasks.length
                    )}
                  </div>
                </div>
              )}

              {!!projectForEdit && (
                <div className="flex-grow-1 flex-shrink-0 max-w-250px">
                  <div className="font-weight-bold m-2">
                    <FormattedMessage id="PROJECT.OWNER" />
                  </div>
                  <div>
                    {readOnly ? (
                      <span className="m-2">
                        {formatUserDisplayName(projectOwnerAssignee, "-")}
                      </span>
                    ) : (
                      <CustomSelect
                        placeholder={intl.formatMessage({
                          id: "PROJECT.SELECT.OWNER",
                        })}
                        name="projectOwner"
                        selected={projectOwnerSelected}
                        options={projectOwnerAssignees as any}
                        clearable={true}
                        disabled={readOnly}
                        onFocus={openProjectOwnerAssigneesSelect}
                        onChange={(selected) => {
                          saveProjectOwner(selected?.value);
                        }}
                        isLoading={projectOwnerAssigneesLoading}
                        loadingMessage={() => intl.formatMessage({ id: "COMMON.LOADING" })}
                        getOptionValue={(o: any) => o.value?.id}
                        styles={{
                          container: {
                            margin: "0.5rem",
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={"separator separator-solid my-7"} />

        <div className="d-flex align-items-center flex-wrap mr-5 my-1">
          {budget?.plannedTotal !== undefined &&
            canReadAll(groups, session, "BUDGET") &&
            ProjectWidget({
              data: budget?.plannedTotal,
              icon: "flaticon-piggy-bank",
              label: "COMMON.PLANNED.EXPENSES",
              suffix: " €",
            })}

          {projectForEdit?.subcontractorsFinanceFiles &&
            canReadAll(groups, session, "BUDGET") &&
            ProjectWidget({
              data:
                (projectForEdit?.subcontractorsFinanceFiles?.orders?.total?.general ?? 0) +
                (projectForEdit?.subcontractorsFinanceFiles?.supplementary_agreements?.total
                  ?.general ?? 0),
              icon: "flaticon-statistics",
              label: "COMMON.REAL.EXPENSES",
              suffix: " €",
            })}

          {totalTasks !== undefined &&
            ProjectWidget({
              data: totalTasks.length,
              icon: "flaticon-file-2",
              label: totalTasks.length > 1 ? "TASKS.COUNT" : "TASK.COUNT",
            })}

          {projectForEdit?.products &&
            ProjectWidget({
              data: projectForEdit?.products?.length,
              icon: "flaticon-home-2",
              label: "COMMON.PROPERTIES",
            })}

          {((projectForEdit && projectForEdit.usersCanAccess?.length) ?? 0) > 0 && (
            <WidgetUserListAccess
              entityName={projectForEdit?.name ?? ""}
              users={(cloneDeep(projectForEdit?.usersCanAccess) as []).sort(
                (a: { profilePictureUrl: string }, b: { profilePictureUrl: string }) => {
                  return (b.profilePictureUrl ?? "").localeCompare(a.profilePictureUrl ?? "");
                }
              )}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProjectDashboard;
