// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import * as uiHelpers from "./UserFilesUIHelper";
import { DocumentTypeColumnFormatter } from "../../../../Common/DocumentTypeColumnFormatter";
import {
  ActionsColumnFormatter,
  RowHighlightIndicatorColumnFormatter,
  UploadedDateTimeColumnFormatter,
} from "../../../../../_components/column-formatters";

import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useUserFilesUIContext } from "./UserFilesUIContext";
import * as actions from "../../../../UsersManagement/_redux/usersActions";
import { useIntl } from "react-intl";
import { useEntityDeleteDialogContext } from "../../../../PropertiesManagement/pages/entity-delete-dialog/EntityDeleteDialogContext";
import { useHistory } from "react-router-dom";
import { searchUtils, selectField } from "../../../../../_utils/searchUtils";
import { USER_FILE_TYPES } from "../../../../../_utils/listUtils";
import { sumBy } from "lodash-es";
import { NumberInput } from "../../../../../_utils/formUtils";
import { CLIENT, SUBCONTRACTOR } from "../../../../../_utils/userTypes";
import { canEditUser, isAdmin, isOwner } from "../../../../../_utils/authUtils";
import { displayColumnIsConfidential } from "../../../../../_utils/columnUtils";
import { isFinanceFile } from "../../../../../../data/schemas";
import { DateUtils } from "../../../../../_utils/DateUtils";

export function UserFilesTable({ files }) {
  const intl = useIntl();
  const history = useHistory();
  const userFilesUIContext = useUserFilesUIContext();
  const { setDeleteEntityDialog } = useEntityDeleteDialogContext();
  const dispatch = useDispatch();

  const userFilesUIProps = useMemo(() => {
    return {
      ids: userFilesUIContext.ids,
      setIds: userFilesUIContext.setIds,
      filters: userFilesUIContext.filters,
      setFilters: userFilesUIContext.setFilters,
      userId: userFilesUIContext.userId,
      openRequestSignatureDialog: userFilesUIContext.openRequestSignatureDialog,
      openEditFileDialog: userFilesUIContext.openEditFileDialog,
      openUploadFileDialog: userFilesUIContext.openUploadFileDialog,
      readOnly: userFilesUIContext.readOnly,
      userTypes: userFilesUIContext.userTypes,
      filterList: userFilesUIContext.filterList,
    };
  }, [userFilesUIContext]);

  const [entitiesFiltered, setEntitiesFiltered] = useState([]);

  const { totalCount, entities, userId, entityForEdit, groups, session } = useSelector(
    (state) => ({
      totalCount: files?.length || state.users?.entityForEdit?.current?.files?.length || 0,
      entities: files ? files : state.users?.entityForEdit?.current?.files,
      userId: state.auth.session.id,
      entityForEdit: state.users?.entityForEdit?.current,
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!entities) {
      dispatch(actions.fetchUserById(userFilesUIProps.userId, userFilesUIProps.userTypes));
    }
  }, [userFilesUIProps.filters, userFilesUIProps.userId]);

  useEffect(() => {
    setEntitiesFiltered(
      searchUtils(
        userFilesUIProps?.filters?.freeText,
        entities,
        selectField(userFilesUIProps),
        intl
      )
    );
  }, [entities, userFilesUIProps?.filters?.freeText, userFilesUIProps?.filters?.filterSelected]);

  // Merge file types available for user if user has multiple types
  const fileTypes = userFilesUIProps.userTypes.reduce(
    (acc, userType) => ({
      ...acc,
      ...USER_FILE_TYPES[userType],
    }),
    {}
  );

  const openDeleteFileDialog = (id) => {
    setDeleteEntityDialog({
      action: { fn: actions.deleteUserFile, props: { id, userId: userFilesUIProps.userId } },
      entityType: "USER.FILE",
    });
  };

  const columns = [
    {
      dataField: "",
      text: "",
      sort: false,
      formatter: RowHighlightIndicatorColumnFormatter,
      formatExtraData: {
        highlightId: new URLSearchParams(history.location.search).get("h"),
      },
    },
    {
      dataField: "id",
      text: "",
      sort: false,
      formatter: DocumentTypeColumnFormatter,
    },
    {
      dataField: "friendlyName",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.NAME",
      }),
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "fileType",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.TYPE",
      }),
      sort: true,
      sortCaret: sortCaret,
      sortValue: (cell, row) =>
        fileTypes?.[cell] ? intl.formatMessage({ id: fileTypes?.[cell] }) : fileTypes?.[cell],
      formatter: (cell) => (
        <span>
          {fileTypes?.[cell] ? intl.formatMessage({ id: fileTypes?.[cell] }) : fileTypes?.[cell]}
        </span>
      ),
    },
    {
      dataField: "uploadedAt",
      headerClasses: "text-left text-nowrap",
      classes: "text-left",
      text: intl.formatMessage({
        id: "COMMON.UPLOADED.DATE",
      }),
      sort: true,
      sortCaret: sortCaret,
      sortValue: (cell) => (cell ? DateUtils.format(new Date(cell), intl) : "-"),
      formatter: UploadedDateTimeColumnFormatter,
      formatExtraData: {
        openUploadFileDialog: userFilesUIProps.openUploadFileDialog,
        readOnly: userFilesUIProps.readOnly,
        userId,
        intl,
      },
    },
    {
      dataField: "categories",
      text: intl.formatMessage({ id: "COMMON.AMOUNT.EXCL_TAX" }),
      sortCaret: sortCaret,
      formatter: (cell, row) =>
        isFinanceFile(row.fileType) ? (
          <NumberInput
            value={sumBy(cell, ({ lines }) => sumBy(lines, "amount"))}
            displayType="text"
            suffix={" €"}
          />
        ) : (
          ""
        ),
      headerClasses: "text-right text-nowrap",
      classes: "text-right",
      hidden: !userFilesUIProps.userTypes.includes(SUBCONTRACTOR),
    },
    {
      dataField: "action",
      text: intl.formatMessage({
        id: "ACTION.TITLE",
      }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditDialog: userFilesUIProps.openEditFileDialog,
        openDeleteDialog: openDeleteFileDialog,
        openRequestSignatureDialog: userFilesUIProps.openRequestSignatureDialog,
        actionList: ["SIGNATURE", "WRITE", "TRASH"],
        // - A User can request signature for a file if he's has userType: "USER" or "SUBCONTRACTOR"
        // - A User of userType: "CLIENT" cannot request signatures from other people
        canRequestSignature: () => {
          return isAdmin(groups, session) || !session?.userTypes?.includes(CLIENT);
        },
        /*
         - users with delete-right can delete the files of (other) users
         - users can delete their own created (/uploaded) files
        */
        canDelete: (row) =>
          isAdmin(groups, session) ||
          canEditUser(groups, session, entityForEdit) ||
          isOwner(session, row),
        /*
         - users with update-right can edit the files of (other) users
         - users can edit their own created (/uploaded) files

         note: If User A requests a file from User B, User B can only upload the file (=complete the file reques).
               User B  CAN'T edit the file-object or the request (because he is not the owner of that file-object)
        */
        canEdit: (row) =>
          isAdmin(groups, session) ||
          canEditUser(groups, session, entityForEdit) ||
          isOwner(session, row),
        entityType: `FILE_${userFilesUIProps.userTypes[0]}`,
      },
      classes: "text-right",
      headerClasses: "text-right",
      style: {
        minWidth: "100px",
      },
      // A user should be able to see action column, in order to be able to edit/delete his own created files
      // note: the visibility of specific actions (edit, delete, request signature) are managed above in canXYZ-functions
      hidden: userFilesUIProps.readOnly && session.id !== userFilesUIProps.userId,
    },
  ];
  displayColumnIsConfidential({ entitiesFiltered, columns });

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: userFilesUIProps.filters.pageSize,
    page: userFilesUIProps.filters.pageNumber,
  };
  return (
    <>
      <div data-cy="table-files">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination paginationProps={paginationProps}>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center"
                  bordered={false}
                  bootstrap4
                  keyField="id"
                  data={entitiesFiltered || []}
                  columns={columns}
                  defaultSorted={uiHelpers.defaultSorted}
                  hover
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      if (row.uploadedAt && row.status === "UPLOADED") {
                        dispatch(actions.openUserFile(row));
                      }
                    },
                  }}
                  rowStyle={{ cursor: "pointer" }}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={entities} />
                  <NoRecordsFoundMessage entities={entities} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </div>
    </>
  );
}
