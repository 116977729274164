// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { DatePickerField, Input, Select } from "../../../../../../_metronic/_partials/controls";
import { formatAddressInline, isAddressValid } from "../../../../../_utils/addressUtils";
import { formatDisplayNameIntl } from "../../../../../_utils/userUtils";
import { NumberInput } from "../../../../../_utils/formUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { LEAD_STATUS } from "../../../../../_utils/listUtils";
import { AddressEditForm } from "../../../../../_components/AddressEditForm";
import { useAppSelector } from "../../../../../../redux/hooks";
import { canReadAll } from "../../../../../_utils/authUtils";
import cn from "clsx";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { DateUtils } from "../../../../../_utils/DateUtils";
import { Checkbox } from "../../../../../_components/Checkbox";

export function LeadEditForm({ lead, submitLead, saveLeadFields, disabled }) {
  const intl = useIntl();

  const { groups, session } = useAppSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  // Validation schema
  const LeadEditSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.LEAD.NAME.REQUIRED" }))
      .min(1, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 1`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
  });

  const customHandleChange = (e) => (handleChange) => {
    if (!!saveLeadFields) {
      handleChange(e);
      saveLeadFields(e.target.name, e.target.value);
      if (e.target.name === "eligibleReducedVat" && e.target.value === false) {
        saveLeadFields("authorisationCode", "");
        saveLeadFields("authorisationDate", "");
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={lead}
        validationSchema={LeadEditSchema}
        onSubmit={() => {
          if (!disabled) {
            submitLead();
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <>
            <Form className="form form-label-right" data-cy="form-lead">
              <div className="form-group row">
                <div className={cn(canReadAll(groups, session, "USER") ? "col-lg-9" : "col-lg-12")}>
                  <Field
                    name="name"
                    data-cy="input-lead-name"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "LEAD.TITLE.NAME",
                    })}
                    label={intl.formatMessage({
                      id: "LEAD.TITLE.NAME",
                    })}
                    onChange={(e) => customHandleChange(e)(handleChange)}
                    disabled={disabled}
                  />
                </div>
                {canReadAll(groups, session, "USER") && (
                  <div className="col-lg-3">
                    <Select
                      data-cy="select-status"
                      name="status"
                      label={intl.formatMessage({
                        id: "COMMON.STATUS",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                      value={lead.status}
                    >
                      {Object.keys(LEAD_STATUS).map((value) => {
                        /* Hide "WON" option if already won once */
                        if (
                          value === "WON" &&
                          lead?.product?.leadStatus?.WON >= 1 &&
                          (!!lead?.id ? lead?.status !== "WON" : true)
                        )
                          return null;

                        return (
                          <option key={value} value={value} disabled={LEAD_STATUS[value].disabled}>
                            {LEAD_STATUS[value].name
                              ? intl.formatMessage({ id: LEAD_STATUS[value].name })
                              : LEAD_STATUS[value].name}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                )}
              </div>
              <div className="form-group row">
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="INVOICE.PRICE.EXCL_TAX" />
                  </label>
                  <NumberInput
                    className="form-control text-right"
                    data-cy="input-lead-selling-price"
                    value={values.sellingPrice}
                    suffix={" €"}
                    decimalScale={2}
                    onValueChange={(e) =>
                      customHandleChange({
                        target: {
                          name: "sellingPrice",
                          value: e.floatValue || 0,
                        },
                      })(handleChange)
                    }
                    disabled={disabled}
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="PRODUCT.INFO.SHARE_OF_LAND" />
                  </label>
                  <NumberInput
                    className="form-control text-right"
                    data-cy="input-lead-share-of-land-selling-price"
                    value={values.shareOfLandSellingPrice}
                    suffix={" €"}
                    decimalScale={2}
                    onValueChange={(e) =>
                      customHandleChange({
                        target: {
                          name: "shareOfLandSellingPrice",
                          value: e.floatValue || 0,
                        },
                      })(handleChange)
                    }
                    disabled={disabled}
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES" />
                  </label>
                  <NumberInput
                    className="form-control text-right"
                    data-cy="input-lead-architect-engineering-fees"
                    value={values.architectEngineeringFees}
                    suffix={" €"}
                    decimalScale={2}
                    onValueChange={(e) =>
                      customHandleChange({
                        target: {
                          name: "architectEngineeringFees",
                          value: e.floatValue || 0,
                        },
                      })(handleChange)
                    }
                    disabled={disabled}
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="PRODUCT.INFO.CONSTRUCTION_PRICE" />
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                          <FormattedMessage id="INVOICE.PRICE.EXCL_TAX" /> - (
                          <FormattedMessage id="PRODUCT.INFO.SHARE_OF_LAND" /> +{" "}
                          <FormattedMessage id="PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES" />)
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                      </span>
                    </OverlayTrigger>
                  </label>
                  <div>
                    <NumberInput
                      displayType="text"
                      value={
                        values.sellingPrice -
                        (values.shareOfLandSellingPrice + values.architectEngineeringFees)
                      }
                      decimalScale={2}
                      suffix={" €"}
                      className="form-control font-weight-bold text-right border-0"
                    />
                  </div>
                </div>
              </div>
              {!disabled && (
                <Checkbox
                  name={"eligibleReducedVat"}
                  label={"LEAD.ELIGIBLE_FOR_REDUCED_VAT"}
                  onChange={(e) => {
                    customHandleChange({
                      target: { name: e.target.name, value: e.target.checked },
                    })(handleChange);
                  }}
                />
              )}
              {values.eligibleReducedVat && !disabled && (
                <>
                  <div className="form-group row">
                    <div className="col-sm-6 col-md-3">
                      <Field
                        name="authorisationCode"
                        data-cy="input-lead-authorisation-code"
                        component={Input}
                        label={intl.formatMessage({
                          id: "LEAD.AUTHORISATION_CODE",
                        })}
                        onChange={(e) => customHandleChange(e)(handleChange)}
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-sm-6 col-md-3 d-flex flex-column">
                      <Field
                        className={
                          "form-control react-datepicker-ignore-onclickoutside no-validation"
                        }
                        name="authorisationDate"
                        showTimeSelect={false}
                        placeholderText={DateUtils.format(new Date(), intl, false)}
                        component={DatePickerField}
                        withFeedbackLabel={false}
                        dateFormat={"dd/MM/yyyy"}
                        onChange={(val) =>
                          customHandleChange({
                            target: {
                              name: "authorisationDate",
                              value: val?.toISOString() ?? null,
                            },
                          })(handleChange)
                        }
                        label={intl.formatMessage({
                          id: "LEAD.AUTHORISATION_DATE",
                        })}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6 col-md-3">
                      <label>
                        <FormattedMessage id="LEAD.INITIAL_CREDIT" />
                      </label>
                      <NumberInput
                        className="form-control text-right"
                        data-cy="input-lead-initial-credit"
                        value={values.initialCredit}
                        suffix={" €"}
                        decimalScale={2}
                        onValueChange={(e) =>
                          customHandleChange({
                            target: {
                              name: "initialCredit",
                              value: e.floatValue || 0,
                            },
                          })(handleChange)
                        }
                        disabled={disabled}
                      />
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <label>
                        <FormattedMessage id="LEAD.REMAINING_CREDIT" />
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                              <FormattedMessage id={"LEAD.REMAINING_CREDIT_DESCRIPTION"} />
                            </Tooltip>
                          }
                        >
                          <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                          </span>
                        </OverlayTrigger>
                      </label>
                      <div>
                        <NumberInput
                          displayType="text"
                          value={lead.remainingCredit}
                          decimalScale={2}
                          suffix={" €"}
                          className="form-control font-weight-bold text-right border-0"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <label>
                        <FormattedMessage id="LEAD.CONSTRUCTION_PRICE_WITH_REDUCED_VAT" />
                      </label>
                      <NumberInput
                        className="form-control text-right"
                        data-cy="input-lead-construction-price-with-reduced-vat"
                        value={values.constructionPriceWithReducedVat}
                        suffix={" €"}
                        decimalScale={2}
                        onValueChange={(e) =>
                          customHandleChange({
                            target: {
                              name: "constructionPriceWithReducedVat",
                              value: e.floatValue || 0,
                            },
                          })(handleChange)
                        }
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </>
              )}
              {lead.users && (
                <>
                  <h3>
                    <FormattedMessage id="INVOICE.ADDRESS" />
                  </h3>
                  <hr className="mt-0" />
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Select
                        name="invoiceAddressType"
                        label={intl.formatMessage({
                          id: "ADDRESS.TYPE",
                        })}
                        onChange={(e) => customHandleChange(e)(handleChange)}
                        disabled={disabled}
                        value={lead.invoiceAddressType}
                      >
                        {lead.users.map((value, index) => (
                          <option
                            key={index}
                            value={value.id}
                            disabled={!isAddressValid(value.address)}
                          >
                            {`${formatDisplayNameIntl(intl, value, true)} - ${formatAddressInline(
                              value.address,
                              intl
                            )}`}
                          </option>
                        ))}
                        <option value="CUSTOM">
                          {intl.formatMessage({
                            id: "CUSTOM.ADDRESS",
                          })}
                        </option>
                      </Select>
                    </div>
                  </div>
                  {lead.invoiceAddressType === "CUSTOM" && (
                    <>
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <Field
                            name="invoiceAddress.name"
                            data-cy="input-lead-address-name"
                            component={Input}
                            onChange={(e) => customHandleChange(e)(handleChange)}
                            placeholder={intl.formatMessage({
                              id: "COMMON.NAME",
                            })}
                            label={intl.formatMessage({
                              id: "COMMON.NAME",
                            })}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                      <AddressEditForm
                        customHandleChange={customHandleChange}
                        handleChange={handleChange}
                        disabled={disabled}
                        prefix={"invoiceAddress."}
                      />
                    </>
                  )}
                </>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
