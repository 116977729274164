import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FormikHandlers,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { v4 as uuid } from "uuid";
import { Input } from "../../../_metronic/_partials/controls";
import { NumberInput } from "../../_utils/formUtils";
import { cloneDeep, sumBy } from "lodash-es";
import { accurateFloatOperation } from "../../_utils/mathUtils";
import { FormattedMessage } from "react-intl";
import { CustomCard, CustomCardLine } from "../Common/CustomCard";
import { useAppSelector } from "../../../redux/hooks";
import { shallowEqual } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { canEdit } from "../../_utils/authUtils";
import { IBudget, TBudgetInstalments } from "../../../data/schemas";
import { NumberFormatValues } from "react-number-format";

export interface BudgetEditInstalmentsProps {
  saveBudgetFields: (key: string | string[], value: any) => void;
  disabled: boolean;
}
export const BudgetEditInstalments: FC<BudgetEditInstalmentsProps> = ({
  saveBudgetFields,
  disabled,
}) => {
  const { handleChange, values, setFieldValue } = useFormikContext<IBudget>();
  const [totalInstalments, setTotalInstalments] = useState(0);
  const [expectedConstructionPrice, setExpectedSellingPrice] = useState(0);
  const [shareOfLandPrice, setShareOfLandPrice] = useState(0);
  const [architectEngineeringFeesPrice, setArchitectEngineeringFeesPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const { project, groups, session } = useAppSelector(
    (state) => ({
      project: state.projects.projectForEdit.saved,
      groups: state.auth.groups,
      session: state.auth.session,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (project?.products) {
      setExpectedSellingPrice(
        sumBy(
          project.products,
          (product) =>
            (product.financialDetails.price ?? 0) -
            (product.financialDetails.shareOfLand ?? 0) -
            (product.financialDetails.architectEngineeringFees ?? 0)
        )
      );
      setTotalPrice(sumBy(project.products, (product) => product.financialDetails.price ?? 0));
      setShareOfLandPrice(
        sumBy(project.products, (product) => product.financialDetails.shareOfLand ?? 0)
      );
      setArchitectEngineeringFeesPrice(
        sumBy(project.products, (product) => product.financialDetails.architectEngineeringFees ?? 0)
      );
    }
  }, [project]);

  useEffect(() => {
    setTotalInstalments(accurateFloatOperation(sumBy(values.budgetInstalments, "instalment"), 4));
  }, [values.budgetInstalments]);

  const addInstalment = (
    e: React.MouseEvent<HTMLButtonElement>,
    budgetInstalmentsHelpers: FieldArrayRenderProps
  ) => {
    e.preventDefault();
    budgetInstalmentsHelpers.push({
      id: uuid(),
      label: "",
      instalment: 0,
    });
  };

  const moveInstalment = (
    setFieldValue: FormikHelpers<IBudget>["setFieldValue"],
    sortedInstalments: TBudgetInstalments
  ) => {
    if (sortedInstalments.length) {
      setFieldValue("budgetInstalments", sortedInstalments);
      const res = sortedInstalments.map(({ chosen, selected, ...keepAttrs }) => keepAttrs);
      saveBudgetFields("budgetInstalments", res);
    }
  };

  const removeInstalment = (
    e: React.MouseEvent<HTMLButtonElement>,
    budgetInstalmentsHelpers: FieldArrayRenderProps,
    instalmentIndex: number
  ) => {
    e.preventDefault();
    budgetInstalmentsHelpers.remove(instalmentIndex);
    const res = cloneDeep(values.budgetInstalments);
    res.splice(instalmentIndex, 1);
    saveBudgetFields("budgetInstalments", res);
  };

  const handleInstalmentNameChange = (
    e: ChangeEvent<HTMLInputElement>,
    instalmentIndex: number,
    handleChange: FormikHandlers["handleChange"]
  ) => {
    if (!!saveBudgetFields) {
      const res = cloneDeep(values.budgetInstalments);
      res[instalmentIndex].label = e.target.value;
      saveBudgetFields("budgetInstalments", res);
      handleChange(e);
    }
  };

  const handleInstalmentValueChange = (value: NumberFormatValues, instalmentIndex: number) => {
    if (!!saveBudgetFields) {
      const res = cloneDeep(values.budgetInstalments);
      res[instalmentIndex].instalment =
        accurateFloatOperation((value.floatValue ?? 0) / 100, 4) || 0;
      saveBudgetFields("budgetInstalments", res);
      setFieldValue("budgetInstalments", res);
    }
  };

  return (
    <>
      {!project ? (
        <div className="d-flex align-items-center justify-content-center">
          <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
        </div>
      ) : (
        <div>
          <div className="row">
            {[
              { label: "COMMON.TOTAL.CONSTRUCTION", value: totalPrice },
              { label: "COMMON.EXPECTED.TOTAL.CONSTRUCTION", value: expectedConstructionPrice },
              { label: "COMMON.EXPECTED.TOTAL.SHARE_OF_LAND", value: shareOfLandPrice },
              {
                label: "COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING",
                value: architectEngineeringFeesPrice,
              },
            ].map((data, index) => (
              <div key={index} className={"col-md-3 card px-0 bg-transparent card-custom"}>
                <div className="card-body d-flex flex-column bg-light m-3">
                  <div className={`text-inverse-light font-weight-bolder font-size-h2`}>
                    <NumberInput
                      value={data.value}
                      displayType="text"
                      suffix={" €"}
                      decimalScale={2}
                      className="ml-auto font-weight-bold"
                    />
                  </div>
                  <div className={`text-inverse-light font-weight-bold font-size-lg mt-1`}>
                    <FormattedMessage id={data.label} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ overflowX: "auto" }}>
            <div className={"min-w-600px"}>
              <CustomCard
                header={
                  <div className="form-row flex-grow-1 mr-12">
                    <div className="col-8 pl-4" />
                    <div className="col-2 text-right">
                      <NumberInput
                        displayType="text"
                        value={accurateFloatOperation(totalInstalments * 100, 2)}
                        suffix={" %"}
                        className={
                          "font-weight-bold " +
                          (totalInstalments < 1
                            ? "text-warning"
                            : totalInstalments > 1
                            ? "text-danger"
                            : "text-success")
                        }
                      />

                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                              <FormattedMessage id="COMMON.PERCENTAGE.CONSTRUCT.PRICE" />
                            </Tooltip>
                          }
                        >
                          <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                            <SVG
                              className={"pb-1"}
                              src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                            />
                          </span>
                        </OverlayTrigger>
                      </>
                    </div>
                    <div className="col-2 text-right">
                      <NumberInput
                        displayType="text"
                        value={
                          accurateFloatOperation(expectedConstructionPrice * totalInstalments, 2) +
                          architectEngineeringFeesPrice +
                          shareOfLandPrice
                        }
                        suffix={" €"}
                        className="font-weight-bold"
                      />
                    </div>
                  </div>
                }
              >
                <div className="d-flex">
                  <div className="w-15px" />
                  <div
                    className="form-row flex-grow-1 px-4 pt-2 font-size-xs font-weight-bold line-height-sm"
                    style={{ color: "#6a6a6a" }}
                  >
                    <div className="col-8">
                      <FormattedMessage id="COMMON.DESCRIPTION" />
                    </div>
                    <div className="col-2">
                      <FormattedMessage id="BUDGET.INSTALMENT.SINGLE" />
                    </div>
                    <div className="col-2">
                      <FormattedMessage id="BUDGET.AMOUNT_PREVIEW" />
                    </div>
                  </div>
                  <div className="w-30px" />
                </div>
                <FieldArray
                  name="budgetInstalments"
                  validateOnChange={false}
                  render={(budgetInstalmentsHelpers) => (
                    <>
                      {[
                        {
                          description: "COMMON.EXPECTED.TOTAL.SHARE_OF_LAND",
                          value: accurateFloatOperation(shareOfLandPrice, 2),
                        },
                        {
                          description: "COMMON.EXPECTED.TOTAL.ARCHITECT_ENGINEERING",
                          value: accurateFloatOperation(architectEngineeringFeesPrice, 2),
                        },
                      ].map((data, index) => (
                        <CustomCardLine
                          draggable={false}
                          key={index}
                          removable={false}
                          remove={undefined}
                        >
                          <div className="form-row flex-grow-1">
                            <div className="col-8 d-flex align-items-center">
                              <div className="flex-grow-1">
                                <FormattedMessage id={data.description} />
                              </div>
                            </div>
                            <div className="col-2 d-flex" />
                            <div className="col-2 d-flex align-items-center justify-content-end">
                              <NumberInput
                                displayType="text"
                                value={data.value}
                                suffix={" €"}
                                className="text-right"
                              />
                            </div>
                          </div>
                        </CustomCardLine>
                      ))}
                      <ReactSortable
                        list={values.budgetInstalments || []}
                        setList={(sortedInstalments) =>
                          moveInstalment(setFieldValue, sortedInstalments)
                        }
                        swapThreshold={0.65}
                        animation={150}
                        handle=".line-handle"
                        scroll={true}
                        bubbleScroll={true}
                      >
                        {values.budgetInstalments?.map((instalment, instalmentIndex) => (
                          <CustomCardLine
                            key={instalment.id}
                            draggable={canEdit(groups, session, "BUDGET")}
                            remove={
                              canEdit(groups, session, "BUDGET")
                                ? (e: React.MouseEvent<HTMLButtonElement>) =>
                                    removeInstalment(e, budgetInstalmentsHelpers, instalmentIndex)
                                : undefined
                            }
                            removable={values.budgetInstalments.length !== 1}
                          >
                            <div className="form-row flex-grow-1">
                              <div className="col-8 d-flex align-items-center">
                                <div className="flex-grow-1">
                                  <Field
                                    name={`budgetInstalments.${instalmentIndex}.id`}
                                    type="hidden"
                                  />

                                  <Field
                                    name={`budgetInstalments.${instalmentIndex}.label`}
                                    className="form-control-sm"
                                    component={Input}
                                    withFeedbackLabel={false}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                      handleInstalmentNameChange(e, instalmentIndex, handleChange)
                                    }
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                              <div className="col-2 d-flex">
                                <NumberInput
                                  className="form-control form-control-sm text-right"
                                  suffix={" %"}
                                  value={accurateFloatOperation(instalment.instalment * 100, 2)}
                                  onValueChange={(e: NumberFormatValues) =>
                                    handleInstalmentValueChange(e, instalmentIndex)
                                  }
                                  disabled={disabled}
                                  decimalScale={2}
                                />
                              </div>
                              <div className="col-2 d-flex align-items-center justify-content-end">
                                <NumberInput
                                  displayType="text"
                                  value={accurateFloatOperation(
                                    expectedConstructionPrice * instalment.instalment,
                                    2
                                  )}
                                  suffix={" €"}
                                  className="text-right"
                                />
                              </div>
                            </div>
                          </CustomCardLine>
                        ))}
                      </ReactSortable>
                      {!disabled && (
                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center justify-content-center"
                            onClick={(e) => addInstalment(e, budgetInstalmentsHelpers)}
                          >
                            <i className="ki ki-plus icon-nm" />
                            <FormattedMessage id="BUDGET.INSTALMENT.ADD" />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                />
              </CustomCard>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
