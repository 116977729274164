import React, { useMemo } from "react";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

import * as actions from "../../../_redux/forms/formsActions";
import { FormattedMessage } from "react-intl";
import { useFormsUIContext } from "../FormsUIProvider";

export function PersonalInfoFilloutConfirmModal({
  action,
  showModal,
  setShowModal,
  actionsLoading,
  previousStep,
  nextStep,
}) {
  const dispatch = useDispatch();

  const formsUIContext = useFormsUIContext();
  const formsUiProps = useMemo(() => {
    return {
      formData: formsUIContext.formData,
      setShowModal: formsUIContext.setShowModal,
    };
  }, [formsUIContext]);

  const { formData } = formsUiProps;

  const uploadEntity = () => {
    const formDataUploadBody = {
      fields: {
        ...formData.fields,
      },
    };

    formsUiProps.setShowModal(false);
    dispatch(actions.uploadForm(formDataUploadBody, action.id, action.userId));
  };

  const goNext = () => {
    uploadEntity();
    setShowModal(false);
    nextStep();
  };

  return (
    <Modal show={showModal} centered>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="FORM.UPLOAD.TITLE.CONFIRM" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!actionsLoading && (
          <>
            <span>
              <FormattedMessage id="FORM.UPLOAD.CONFIRM.PERSONAL_INFORMATION" />
            </span>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={previousStep} className="btn btn-light btn-elevate">
            <FormattedMessage id="COMMON.ACTION.NO" />
          </button>
          <> </>
          <button
            data-cy="button-form-submit-confirm"
            type="button"
            onClick={goNext}
            className="btn btn-primary font-weight-bold"
            disabled={actionsLoading}
          >
            <FormattedMessage id="COMMON.ACTION.YES_SUBMIT" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
