// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { DatePickerField, Input, Select } from "../../../../../../_metronic/_partials/controls";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { NumberInput } from "../../../../../_utils/formUtils";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { PRODUCT_TYPES } from "../../../../../_utils/listUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { canReadAll } from "../../../../../_utils/authUtils";
import cn from "clsx";
import { isAddressValid } from "../../../../../_utils/addressUtils";
import { AddressEditForm } from "../../../../../_components/AddressEditForm";
import { Urls } from "../../../../../_components/Urls";
import { TextEditor } from "../../../../../_components/TextEditor/TextEditor";

const initProduct = {
  id: undefined,
  name: "",
  description: "",
  projectId: "",
  projectName: "",
  productType: "-",
  urls: [],
};

export function ProductEditForm({
  product,
  submitProduct,
  saveProductFields,
  disabled,
  hiddenFields = [],
  setFromUrlTo,
  projects,
  projectsListLoading,
  onSubmitRef,
}) {
  const intl = useIntl();
  const [currentProject, setCurrentProject] = useState({});

  const { groups, session } = useAppSelector((state) => ({
    groups: state.auth.groups,
    session: state.auth.session,
  }));

  // Validation schema
  const ProductEditSchema = Yup.object().shape({
    projectId: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
    projectName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
    name: Yup.string()
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.PRODUCT.NAME.REQUIRED" }))
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_CHARACTER" })} 2`)
      .max(250, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`),
    description: Yup.string(),
    productType: Yup.string().max(
      250,
      `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_CHARACTER" })} 250`
    ),
    address: Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      city: Yup.string(),
      stateOrRegion: Yup.string(),
      postalCode: Yup.string(),
      countryCode: Yup.string(),
    }),
    availability: Yup.date(),
    constructionYear: Yup.number(),
  });

  const handleProjectChange = (e) => (handleChange) => {
    if (!!saveProductFields) {
      handleChange(e);
      saveProductFields(e.target.name, e.target.value);
      const project = projects.find((project) => project.id === e.target.value);
      if (e.target.name === "projectId") {
        const projectName = project?.name ? project.name : "";
        saveProductFields("projectName", projectName);
        if (!product?.propertyDetails?.housingProject) {
          saveProductFields("propertyDetails.housingProject", projectName);
        }
        setCurrentProject(project);
        saveProductFields("addressType", "CUSTOM");
        saveProductFields("additionalInformationType", "CUSTOM");
      }
      if (e.target.name === "addressType" && project) {
        saveProductFields("address", project?.address);
      }
      if (e.target.name.includes("address.")) {
        saveProductFields("addressType", "CUSTOM");
      }
      if (e.target.name === "additionalInformationType" && project) {
        saveProductFields("availability", project.availability);
        saveProductFields("constructionYear", project.constructionYear);
      }
      if (e.target.name === "availability" || e.target.name === "constructionYear") {
        saveProductFields("additionalInformationType", "CUSTOM");
      }
    }
  };
  const customHandleChange = (e) => (handleChange) => {
    if (!!saveProductFields) {
      handleChange(e);
      saveProductFields(e.target.name, e.target.value);
    }
  };

  const formRef = useRef();

  useEffect(() => {
    if (product && product.projectId && product.projectId !== "CUSTOM") {
      const project = projects.find((project) => project.id === product.projectId);
      if (project) {
        setCurrentProject(project);
      }
    }
  }, [product.projectId, projects]);

  useEffect(() => {
    if (onSubmitRef) {
      onSubmitRef(() => formRef.current.submitForm());
    }
  }, []);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{ ...initProduct, ...product }}
      validationSchema={ProductEditSchema}
      onSubmit={() => {
        if (!disabled && !!submitProduct) {
          submitProduct();
        }
      }}
    >
      {({ values, handleChange, setFieldValue }) => (
        <>
          <Form className="form form-label-right" data-cy="form-product">
            <div className="form-group row">
              <div
                className={cn(canReadAll(groups, session, "PRODUCT") ? "col-lg-9" : "col-lg-12")}
              >
                <Field
                  name="name"
                  data-cy="input-product-name"
                  component={Input}
                  onChange={(e) => customHandleChange(e)(handleChange)}
                  label={intl.formatMessage({
                    id: "PROPERTY.NAME",
                  })}
                  disabled={disabled}
                />
              </div>
              {canReadAll(groups, session, "PRODUCT") && (
                <div className="col-lg-3">
                  <Select
                    name="productType"
                    data-cy="select-product-type"
                    label={intl.formatMessage({
                      id: "COMMON.TYPE",
                    })}
                    onChange={(e) => handleProjectChange(e)(handleChange, setFieldValue)}
                    disabled={disabled}
                    value={product?.productType}
                  >
                    {Object.keys(PRODUCT_TYPES).map((value) => (
                      <option key={value} value={value}>
                        {intl.formatMessage({ id: PRODUCT_TYPES[value] })}
                      </option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            {!hiddenFields.includes("projectId") && (
              <>
                <div className="form-group d-flex align-items-end">
                  <div className="flex-grow-1 position-relative">
                    {projectsListLoading && (
                      <Spinner
                        animation="border"
                        variant="primary"
                        className="position-absolute"
                        style={{ top: "30px", left: "20px" }}
                      />
                    )}
                    <Select
                      name="projectId"
                      label={intl.formatMessage({
                        id: "PROJECT.SELECT",
                      })}
                      data-cy="select-product-project"
                      onChange={(e) => handleProjectChange(e)(handleChange)}
                      disabled={
                        disabled ||
                        (product?.projectId && !projects?.find((p) => p.id === product.projectId))
                      }
                      value={product.projectId}
                    >
                      <option value={""}>-</option>
                      {!!product?.projectId &&
                        !!product?.projectName &&
                        !projects?.find((p) => p.id === product.projectId) && (
                          <option value={product.projectId} defaultValue>
                            {product.projectName}
                          </option>
                        )}
                      {projects.map((project, index) => (
                        <option key={index} value={project.id}>
                          {project.name}
                        </option>
                      ))}
                    </Select>
                  </div>

                  {product?.projectId &&
                    ((typeof session.config?.hasProjectAccess === "boolean" &&
                      session.config?.hasProjectAccess) ||
                      session.config?.hasProjectAccess.find(
                        (projectId) => projectId === product.projectId
                      )) && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="product-project-tooltip">
                            <FormattedMessage id="PRODUCT.PROJECT_PAGE" />
                          </Tooltip>
                        }
                      >
                        <span
                          className="btn btn-icon btn-primary ml-8"
                          onClick={() =>
                            setFromUrlTo({
                              url: `/projects/${product.projectId}`,
                              name: product.name,
                            })
                          }
                        >
                          <span className="svg-icon svg-icon-md">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Attachment1.svg")} />
                          </span>
                        </span>
                      </OverlayTrigger>
                    )}
                </div>
              </>
            )}

            {canReadAll(groups, session, "PRODUCT") && (
              <div className="form-group row">
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="COMMON.INVOICE.LISTED_PRICE" />
                  </label>
                  <NumberInput
                    data-cy="input-product-price"
                    className="form-control text-right"
                    value={values.financialDetails?.price ?? 0}
                    suffix={" €"}
                    decimalScale={2}
                    onValueChange={(e) =>
                      customHandleChange({
                        target: { name: "financialDetails.price", value: e.floatValue || 0 },
                      })(handleChange)
                    }
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="PRODUCT.INFO.SHARE_OF_LAND" />
                  </label>
                  <NumberInput
                    className="form-control text-right"
                    value={values.financialDetails?.shareOfLand ?? 0}
                    suffix={" €"}
                    decimalScale={2}
                    onValueChange={(e) =>
                      customHandleChange({
                        target: {
                          name: "financialDetails.shareOfLand",
                          value: e.floatValue || 0,
                        },
                      })(handleChange)
                    }
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES" />
                  </label>
                  <NumberInput
                    className="form-control text-right"
                    value={values.financialDetails?.architectEngineeringFees ?? 0}
                    suffix={" €"}
                    decimalScale={2}
                    onValueChange={(e) =>
                      customHandleChange({
                        target: {
                          name: "financialDetails.architectEngineeringFees",
                          value: e.floatValue || 0,
                        },
                      })(handleChange)
                    }
                    disabled={disabled}
                    placeholder=""
                  />
                </div>
                <div className="col-sm-6 col-md-3">
                  <label>
                    <FormattedMessage id="PRODUCT.INFO.CONSTRUCTION_PRICE" />
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="layout-tooltip" className={"tooltip-auto-width"}>
                          <FormattedMessage id="COMMON.INVOICE.LISTED_PRICE" /> - (
                          <FormattedMessage id="PRODUCT.INFO.SHARE_OF_LAND" /> +{" "}
                          <FormattedMessage id="PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES" />)
                        </Tooltip>
                      }
                    >
                      <span className="svg-icon svg-icon-md svg-icon-info ml-2">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                      </span>
                    </OverlayTrigger>
                  </label>
                  <div>
                    <NumberInput
                      displayType="text"
                      value={
                        (values.financialDetails.price ?? 0) -
                        ((values.financialDetails.shareOfLand ?? 0) +
                          (values.financialDetails.architectEngineeringFees ?? 0))
                      }
                      decimalScale={2}
                      suffix={" €"}
                      className="form-control font-weight-bold text-right border-0"
                    />
                  </div>
                </div>
              </div>
            )}

            {!disabled && (
              <div className="form-group row">
                <div className="col-lg-12">
                  <Select
                    name="additionalInformationType"
                    label={intl.formatMessage({
                      id: "ADDITIONAL.INFORMATION",
                    })}
                    onChange={(e) => handleProjectChange(e)(handleChange)}
                    disabled={!projects?.find((project) => project.id === product?.projectId)}
                  >
                    <option value="CUSTOM">
                      {intl.formatMessage({ id: "CUSTOM.INFORMATION" })}
                    </option>
                    {product?.projectId && (
                      <option
                        value={product?.projectId}
                        disabled={!currentProject.availability && !currentProject.constructionYear}
                      >
                        {product?.projectName}
                      </option>
                    )}
                  </Select>
                </div>
              </div>
            )}

            <div className="form-group form-row">
              <div className="col-6 d-flex flex-column">
                <Field
                  className={"form-control react-datepicker-ignore-onclickoutside no-validation"}
                  name="availability"
                  component={DatePickerField}
                  onChange={(val) =>
                    handleProjectChange({
                      target: { name: "availability", value: val?.toISOString() ?? null },
                    })(handleChange)
                  }
                  label={intl.formatMessage({
                    id: "PROJECT.INFO.AVAILABILITY_DATE",
                  })}
                  withFeedbackLabel={false}
                  disabled={disabled}
                  showTimeSelect={false}
                  dateFormat={"P"}
                  popperPlacement={"bottom-start"}
                  autoComplete={"off"}
                />
              </div>

              <div className="col-6">
                <Field
                  name="constructionYear"
                  component={Input}
                  type={"number"}
                  onChange={(e) => handleProjectChange(e)(handleChange)}
                  label={intl.formatMessage({
                    id: "PROJECT.INFO.CONSTRUCTION_YEAR",
                  })}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="form-group">
              <label>
                <FormattedMessage id="COMMON.DESCRIPTION" />
              </label>
              <TextEditor
                data-cy="input-product-description"
                name={"description"}
                data={values?.description}
                saveMethod={saveProductFields}
                disabled={disabled}
              />
            </div>

            {!disabled && (
              <div className="form-group row">
                <div className="col-lg-12">
                  <Select
                    name="addressType"
                    label={intl.formatMessage({
                      id: "ADDRESS.TYPE",
                    })}
                    onChange={(e) => handleProjectChange(e)(handleChange)}
                    disabled={!projects?.find((project) => project.id === product?.projectId)}
                  >
                    <option value="CUSTOM">{intl.formatMessage({ id: "CUSTOM.ADDRESS" })}</option>
                    {product?.projectId && (
                      <option
                        value={product?.projectId}
                        disabled={!isAddressValid(currentProject?.address)}
                      >
                        {product?.projectName}
                      </option>
                    )}
                  </Select>
                </div>
              </div>
            )}

            <AddressEditForm
              handleChange={handleChange}
              customHandleChange={handleProjectChange}
              disabled={
                disabled ||
                (product?.addressType === product?.projectId &&
                  !projects?.find((project) => project.id === product?.projectId))
              }
              hideGPSCoordinates={false}
            />

            <Urls disabled={disabled} values={values} saveFields={saveProductFields} />
          </Form>
        </>
      )}
    </Formik>
  );
}
