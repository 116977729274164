import { formatCurrency } from "../../_utils/mathUtils";
import {
  TFormattedBudgetLine,
  SFFRelatedBudgetFinanceType,
  SFFRelatedBudgetFinanceTypes,
  SubcontractorFinanceType,
  SubcontractorsFinanceFiles,
} from "../../../data/schemas";
import { IntlShape } from "react-intl";

export const getSubcontractorFilesCategoryTotals = (
  subcontractorsFinanceFiles: SubcontractorsFinanceFiles | undefined,
  categoryId: string
) =>
  Object.values(SubcontractorFinanceType).reduce(
    (acc, sffType) => ({
      ...acc,
      [SFFRelatedBudgetFinanceType[sffType]]:
        subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.sum,
    }),
    {} as Record<SFFRelatedBudgetFinanceTypes, number | undefined>
  );

export const getSubcontractorFilesLine = (
  subcontractorsFinanceFiles: SubcontractorsFinanceFiles | undefined,
  categoryId: string,
  budgetLineId: string
): Record<SFFRelatedBudgetFinanceTypes, number | undefined> =>
  Object.values(SubcontractorFinanceType).reduce(
    (acc, sffType) => ({
      ...acc,
      [SFFRelatedBudgetFinanceType[sffType]]: Object.values(
        subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines ?? {}
      )?.find((line) => line.budgetLineId === budgetLineId)?.amount,
    }),
    {} as Record<SFFRelatedBudgetFinanceTypes, number | undefined>
  );

export const formatUnspecifiedLines = (
  subcontractorsFinanceFiles: SubcontractorsFinanceFiles | undefined,
  categoryId: string,
  intl: IntlShape
) =>
  Object.values(SubcontractorFinanceType).reduce<TFormattedBudgetLine[]>(
    (acc, sffType) => [
      ...acc,
      ...(Object.entries(subcontractorsFinanceFiles?.[sffType]?.data?.[categoryId]?.lines ?? {})
        ?.filter(([, line]) => !line.budgetLineId)
        .map(([id, line]) =>
          formatBudgetLine({
            id,
            [SFFRelatedBudgetFinanceType[sffType]]: line.amount,
            intl,
            label: line.label,
          })
        ) ?? []),
    ],
    []
  );

type FormatBudgetLineProps = {
  planned?: number;
  intl: IntlShape;
  label?: any;
  id?: string;
} & Partial<Record<SFFRelatedBudgetFinanceTypes, number>>;
export const formatBudgetLine = ({
  id,
  planned,
  quotes,
  supplementary_agreements,
  orders,
  invoiced,
  intl,
  label,
}: FormatBudgetLineProps): TFormattedBudgetLine => {
  const totalReal = (orders ?? 0) + (supplementary_agreements ?? 0);
  const realDifferenceCategory = totalReal && planned ? totalReal - planned : 0;
  return {
    id,
    label,
    planned: planned ? formatCurrency(planned, 2, intl) : "-",
    quotes: quotes ? formatCurrency(quotes, 2, intl) : "-",
    supplementary_agreements: supplementary_agreements
      ? formatCurrency(supplementary_agreements, 2, intl)
      : "-",
    orders: orders ? formatCurrency(orders, 2, intl) : "-",
    realDifference:
      totalReal && planned
        ? (realDifferenceCategory >= 0 ? "+" : "") + formatCurrency(realDifferenceCategory, 2, intl)
        : "-",
    realDifferencePercentage:
      totalReal && planned
        ? (realDifferenceCategory >= 0 ? "+" : "") +
          intl.formatNumber((realDifferenceCategory / planned) * 100, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) +
          " %"
        : "-",
    invoiced: invoiced ? formatCurrency(invoiced, 2, intl) : "-",
    invoicedPercentage:
      invoiced && totalReal
        ? intl.formatNumber((invoiced / totalReal) * 100, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) + " %"
        : "-",
  };
};
