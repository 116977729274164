import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  DatePickerField,
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import { AddressEditForm } from "../../../../../_components/AddressEditForm";
import { BooleanOption } from "../../../../../../_metronic/_partials/controls/forms/BooleanOption";
import {
  EMPLOYER_SECTOR,
  EMPLOYMENT_STATUS,
  SOURCE_OF_FUNDS,
} from "../../../../../_utils/listUtils";
import { cloneDeep, get, mergeWith } from "lodash-es";
import { DateUtils } from "../../../../../_utils/DateUtils";
import { useFormsUIContext } from "../FormsUIProvider";
import { KycAmlFilloutFormValidationSchema } from "../../../../../_validation/KycAmlFilloutFormValidationSchema";

const initForm = {
  fields: {
    id: undefined,
    firstName: "",
    lastName: "",
    birthDate: "",
    birthPlace: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateOrRegion: "",
      postalCode: "",
      countryCode: "",
    },
    tin: "",
    usCitizen: "",
    ownUsPermit: "",
    isPep: "",
    employmentStatus: "EMPTY.STRING",
    employerName: "",
    employerSector: "EMPTY.STRING",
    sourceOfFunds: "EMPTY.STRING",
    iban: "",
  },
};

export const KycAmlFilloutCard = ({ session, submitForm, saveFormFields, disabled, title }) => {
  const intl = useIntl();

  const formsUIContext = useFormsUIContext();
  const formsUiProps = useMemo(() => {
    return {
      setFormData: formsUIContext.setFormData,
      formDataFields: formsUIContext.formData?.fields,
    };
  }, [formsUIContext]);

  const { setFormData, formDataFields } = formsUiProps;

  useEffect(() => {
    setFormData(initForm);
  }, []);

  useEffect(() => {
    if (!!session) {
      const userFields = ["firstName", "lastName"];
      const clientFields = [
        "address.addressLine1",
        "address.addressLine2",
        "address.city",
        "address.stateOrRegion",
        "address.postalCode",
        "address.countryCode",
      ];
      const booleanFields = ["usCitizen", "ownUsPermit", "isPep"];
      const fieldsToUpdate = [];
      const values = [];

      for (const field of userFields) {
        if (
          !get(formDataFields, field) &&
          !!get(session, field) &&
          get(formDataFields, field) !== get(session, field)
        ) {
          fieldsToUpdate.push(field);
          values.push(get(session, field));
        }
      }
      if (!!session) {
        for (const field of clientFields) {
          if (!get(formDataFields, field) && !!get(session, field)) {
            fieldsToUpdate.push(field);
            values.push(get(session, field));
          }
        }
      }
      for (const field of booleanFields) {
        if (!get(formDataFields, field)) {
          fieldsToUpdate.push(field);
          values.push("COMMON.ACTION.NO");
        }
      }
      saveFormFields(fieldsToUpdate, values);
    }
  }, [session]);

  const mergeWithInitForm = (obj) => {
    return mergeWith(cloneDeep(initForm), obj, (dest, src) => (src === null ? dest : undefined));
  };

  const customHandleChange = (e) => (handleChange) => {
    if (!!saveFormFields) {
      handleChange(e);
      saveFormFields(e.target.name, e.target.value);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formDataFields ? mergeWithInitForm(formDataFields) : initForm}
      validationSchema={KycAmlFilloutFormValidationSchema(intl)}
      onSubmit={() => {
        if (!disabled) {
          submitForm();
        }
      }}
    >
      {({ handleChange, validateForm }) => (
        <>
          <Form className="form form-label-right" data-cy="form-kyc-aml">
            <Card>
              <CardHeader title={title ? intl.formatMessage({ id: title }) : ""} />
              <CardBody>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Field
                      data-cy="input-first-name"
                      name="firstName"
                      component={Input}
                      label={intl.formatMessage({
                        id: "COMMON.NAME.FIRST",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      data-cy="input-last-name"
                      name="lastName"
                      component={Input}
                      label={intl.formatMessage({
                        id: "COMMON.NAME.LAST",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6 d-flex flex-column" data-cy="datepicker-birth-date">
                    <Field
                      name="birthDate"
                      component={DatePickerField}
                      onChange={(val) =>
                        customHandleChange({
                          target: { name: "birthDate", value: val?.toISOString() ?? null },
                        })(handleChange)
                      }
                      placeholderText={DateUtils.format(new Date("12/24/1970"), intl, false)}
                      label={intl.formatMessage({
                        id: "COMMON.BIRTH_DATE",
                      })}
                      withFeedbackLabel={false}
                      disabled={disabled}
                      showTimeSelect={false}
                      dateFormat={"P"}
                      popperPlacement={"bottom-start"}
                      autoComplete={"off"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      data-cy="input-birth-place"
                      name="birthPlace"
                      component={Input}
                      label={intl.formatMessage({
                        id: "COMMON.BIRTH_PLACE",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <hr />

                <AddressEditForm
                  customHandleChange={customHandleChange}
                  handleChange={handleChange}
                  disabled={disabled}
                />

                <hr />

                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      data-cy="input-tin"
                      name="tin"
                      component={Input}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "COMMON.TIN.LONG",
                      })}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      dataCy="radio-us-citizen"
                      name="usCitizen"
                      component={BooleanOption}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "KYC.LABEL.QUESTION.US_CITIZEN",
                      })}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      dataCy="radio-us-permit"
                      name="ownUsPermit"
                      component={BooleanOption}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "KYC.LABEL.QUESTION.OWN_US_PERMIT",
                      })}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      dataCy="radio-is-pep"
                      name="isPep"
                      component={BooleanOption}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      label={intl.formatMessage({
                        id: "KYC.LABEL.QUESTION.POLITICALLY_EXPOSED",
                      })}
                      disabled={disabled}
                    />
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <div className="col-lg-4">
                    <Select
                      data-cy="select-employment-status"
                      name="employmentStatus"
                      label={intl.formatMessage({
                        id: "KYC.LABEL.EMPLOYMENT.STATUS",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    >
                      {EMPLOYMENT_STATUS?.map((v) => (
                        <option key={v} value={v}>
                          {intl.formatMessage({ id: v })}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-lg-4">
                    <Field
                      data-cy="input-employer-name"
                      name="employerName"
                      component={Input}
                      label={intl.formatMessage({
                        id: "KYC.LABEL.EMPLOYER.NAME",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      withFeedbackLabel={true}
                      data-cy="select-employer-sector"
                      name="employerSector"
                      label={intl.formatMessage({
                        id: "KYC.LABEL.EMPLOYER.SECTOR",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    >
                      {EMPLOYER_SECTOR?.map((v) => (
                        <option key={v} value={v}>
                          {intl.formatMessage({ id: v })}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <hr />

                <div className="form-group row">
                  <div className="col-lg-6">
                    <Select
                      data-cy="select-origin-of-funds"
                      name="sourceOfFunds"
                      label={intl.formatMessage({
                        id: "KYC.LABEL.QUESTION.SOURCE_OF_FUNDS",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    >
                      {SOURCE_OF_FUNDS.map((v) => (
                        <option key={v} value={v}>
                          {intl.formatMessage({ id: v })}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-lg-6">
                    <Field
                      data-cy="iban"
                      name="iban"
                      className="text-uppercase"
                      component={Input}
                      placeholder="LU28 0019 4006 4475 0000"
                      label={intl.formatMessage({
                        id: "KYC.LABEL.IBAN",
                      })}
                      onChange={(e) => customHandleChange(e)(handleChange)}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </CardBody>
              <CardFooter className="d-flex justify-content-end">
                <div className="text-right">
                  <button
                    type="submit"
                    className="btn btn-primary ml-2"
                    onClick={() =>
                      validateForm().then((values) => {
                        if (!values) submitForm();
                      })
                    }
                    data-cy="button-next"
                  >
                    <FormattedMessage id="COMMON.ACTION.NEXT" />
                  </button>
                </div>
              </CardFooter>
            </Card>
          </Form>
        </>
      )}
    </Formik>
  );
};
