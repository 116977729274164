import * as React from "react";

export interface FormSectionRowProps {
  className?: string;
}

export const FormSectionRow: React.FunctionComponent<FormSectionRowProps> = ({
  className = "form-group form-row justify-content-between justify-content-lg-start",
  children,
}) => <div className={className}>{children}</div>;

export default FormSectionRow;
