import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-displaynames/locale-data/en";
import "@formatjs/intl-displaynames/locale-data/fr";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/fr";

import enMessages from "./messages/en-GB";
import frMessages from "./messages/fr-FR";
import deMessages from "./messages/de-DE";

const allMessages = {
  "en-GB": enMessages,
  "fr-FR": frMessages,
  "de-DE": deMessages,
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} onError={(err) => {
      console.warn(err);
    }}>

      {children}
    </IntlProvider>
  );
}
