import React, { createContext, FC, useContext, useMemo } from "react";

const I18N_CONFIG_KEY = "i18nConfig";

export enum LanguageType {
  ENGLISH = "en-GB",
  FRENCH = "fr-FR",
  GERMAN = "de-DE"
}

export const supportedLanguages = Object.values(LanguageType) as string[];

export const selectLanguage = (lang: string) => lang && supportedLanguages.includes(lang) ? lang : LanguageType.ENGLISH;

export const setI18Language = (selectedLang: string) =>  {
  selectedLang = selectLanguage(selectedLang);
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang }));
}

export const getI18Language = () => {
  return localStorage.getItem(I18N_CONFIG_KEY);
}

export const browserLang = navigator.language;

export interface ContextProps {
  selectedLang: LanguageType;
}
const initialState: ContextProps = {
  selectedLang: selectLanguage(browserLang) as LanguageType,
};

const getConfig = () => {
  const ls = getI18Language();

  if (ls) {
    try {
      return JSON.parse(ls) as ContextProps;
    } catch (er) {
      console.error(er);
    }
  }

  return initialState;
}

// Side effect
export const setLanguage = (lang: LanguageType) => {
  setI18Language(lang)
  window.location.reload();
}

const I18nContext = createContext<ContextProps>(initialState);

export const useLang = () => {
  return useContext(I18nContext).selectedLang;
}

export const MetronicI18nProvider: FC = ({ children }) => {
  const lang = useMemo(getConfig, []);
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
}
