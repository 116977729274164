/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import {
  DatePickerField,
  Input,
  ModalProgressBar,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import * as projectActions from "../../../_redux/projects/projectsActions";
import * as budgetActions from "../../../../Budget/_redux/budgetsActions";
import { NumberInput } from "../../../../../_utils/formUtils";
import { cloneDeep, mergeWith, sumBy } from "lodash-es";
import { ReactSortable } from "react-sortablejs";
import CreatableSelect from "react-select/creatable";
import * as actions from "../../../_redux/leads/leadsActions";
import { useLeadFilesUIContext } from "./LeadFilesUIContext";
import { accurateFloatOperation } from "../../../../../_utils/mathUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomCard, CustomCardLine } from "../../../../Common/CustomCard";
import { formatDisplayNameIntl } from "../../../../../_utils/userUtils";
import { INVOICE_STATUS } from "../../../../../_utils/listUtils";
import { DateUtils } from "../../../../../_utils/DateUtils";
import { Highlighter, Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { fetchSettings } from "../../../../Settings/_redux/settingsActions";
import logout from "../../../../Auth/pages/Logout";
import { isBudgetServiceAvailable } from "../../../../../_utils/configUtils";
import { useAppSelector } from "../../../../../../redux/hooks";
import { canReadAll, canReadAllDocument, isOwner } from "../../../../../_utils/authUtils";
import { Checkbox } from "../../../../../_components/Checkbox";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { v4 as uuid } from "uuid";

const invoiceInit = {
  friendlyName: undefined,
  invoiceStatus: "TO_BE_PAID",
  dueDate: undefined,
  amountPaidToDate: 0,
  isConfidential: true,
  content: [
    {
      id: uuid(),
      budgetInstalmentId: undefined,
      label: "",
      amount: 0,
      vat: 0,
    },
  ],
  bankAccounts: [],
};

const mergeWithInitInvoice = (obj) => {
  return mergeWith(cloneDeep(invoiceInit), obj, (dest, src) => (src === null ? dest : undefined));
};

export function LeadFileInvoiceDialog() {
  const intl = useIntl();
  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);
  const leadFilesUIContext = useLeadFilesUIContext();
  const leadFilesUIProps = useMemo(() => {
    return {
      showInvoiceDialog: leadFilesUIContext.showInvoiceDialog,
      closeInvoiceFileDialog: leadFilesUIContext.closeInvoiceFileDialog,
      newFile: leadFilesUIContext.newFile,
      selectedId: leadFilesUIContext.selectedId,
    };
  }, [leadFilesUIContext]);
  const dispatch = useDispatch();

  const [budgetInstalments, setBudgetInstalments] = useState();
  const {
    isLoading,
    lead,
    projectId,
    project,
    budget,
    session,
    groups,
    leadFileForEdit,
    settings,
  } = useAppSelector(
    (state) => ({
      lead: state.leads?.leadForEdit?.current,
      isLoading: state.leads?.actionsLoading,
      projectId: state.leads?.leadForEdit?.saved?.projectId,
      leadFileForEdit: state.leads?.leadForEdit?.current?.files?.find(
        (file) => file.id === leadFilesUIProps.selectedId
      ),
      project:
        state.projects?.projectForEdit?.saved?.id === state.leads?.leadForEdit?.saved?.projectId
          ? state.projects?.projectForEdit?.saved
          : state?.projects?.entities?.find(
              (project) => project.id === state.leads?.leadForEdit?.saved?.projectId
            ),
      budget: state.budgets?.budgetForEdit?.saved,
      session: state.auth.session,
      groups: state.auth.groups,
      settings: state.settings?.settingsForEdit?.saved,
    }),
    shallowEqual
  );

  const [remainingCredit, setRemainingCredit] = useState(0);
  const [initialValues, setInitialValues] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setInitialValues(
      mergeWithInitInvoice({
        friendlyName: "INVOICE #" + DateUtils.format(new Date(), intl),
        ...leadFileForEdit,
      })
    );
  }, [leadFilesUIProps.showInvoiceDialog, leadFileForEdit]);

  useEffect(() => {
    if (!settings) {
      dispatch(fetchSettings(() => logout()));
    }
  }, [settings]);

  // Validation schema
  const InvoiceSchema = Yup.object().shape({
    friendlyName: Yup.string().min(2).max(250).required().label("INVOICE.TITLE.TITLE"),
    invoiceStatus: Yup.string(),
    amount: Yup.number(),
    amountPaidToDate: Yup.number(),
    content: Yup.array().of(
      Yup.object().shape({
        budgetInstalmentId: Yup.string(),
        label: Yup.string().required(),
        amount: Yup.number().required(),
        vat: Yup.number().required(),
      })
    ),
  });
  if (!leadFilesUIProps.newFile) {
    delete InvoiceSchema.fields.content;
  }

  useEffect(() => {
    if (isBudgetAvailable && budget && budget.projectId === project?.id) {
      setBudgetInstalments([
        { id: "SHARE_OF_LAND", label: intl.formatMessage({ id: "PRODUCT.INFO.SHARE_OF_LAND" }) },
        {
          id: "ARCHITECT_ENGINEERING_FEES",
          label: intl.formatMessage({ id: "PRODUCT.INFO.ARCHITECT_ENGINEERING_FEES" }),
        },
        ...budget.budgetInstalments.map((instalment) => ({
          ...instalment,
          label: `${instalment.label} - ${accurateFloatOperation(
            instalment.instalment * 100,
            2
          )} %`,
        })),
      ]);
    }
  }, [budget]);

  useEffect(() => {
    // TODO better check
    if (projectId && !project && !session.userTypes?.includes("CLIENT")) {
      dispatch(projectActions.fetchProject(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (
      project?.selectedBudget &&
      (!budget || budget.projectId !== project?.id) &&
      canReadAll(groups, session, "BUDGET")
    ) {
      dispatch(budgetActions.fetchBudget(project?.selectedBudget));
    }
  }, [project]);

  const changeLine = (selected, lineIndex, setFieldValue, values) => {
    const res = cloneDeep(values.content);
    res[lineIndex].budgetInstalmentId = selected?.id;
    res[lineIndex].label = selected?.label;
    res[lineIndex].vat = (settings.defaultVat ?? 0) * 100;
    if (selected?.id === "SHARE_OF_LAND") {
      res[lineIndex].amount = lead.shareOfLandSellingPrice;
      res[lineIndex].vat = 0;
    } else if (selected?.id === "ARCHITECT_ENGINEERING_FEES") {
      res[lineIndex].amount = lead.architectEngineeringFees;
    } else {
      if (shouldUseReducedVat(values.content, lineIndex, res[lineIndex])) {
        res[lineIndex].vat = (settings.reducedVat ?? 0) * 100;
      }
      res[lineIndex].amount = accurateFloatOperation(
        selected?.instalment *
          (lead.sellingPrice - (lead.shareOfLandSellingPrice + lead.architectEngineeringFees)),
        2
      );
    }
    setFieldValue("content", res);
  };

  const moveLine = (values, sortedLines, setFieldValue) => {
    if (sortedLines.length) {
      setFieldValue("content", sortedLines);
      handleLineChange(sortedLines, setFieldValue);
    }
  };

  const handleValueChange = (field, values, value, setFieldValue) => {
    const res = cloneDeep(values);
    res[field] = value;
    setFieldValue(field, res[field]);
  };

  const handleLineValueChange = (field, values, value, lineIndex, setFieldValue) => {
    const content = cloneDeep(values.content);
    content[lineIndex][field] = value;
    setFieldValue("content", content);
    handleLineChange(content, setFieldValue);
  };

  const cancelInvoice = (resetForm) => {
    resetForm();
    setSelectedBankAccounts([]);
    leadFilesUIProps.closeInvoiceFileDialog();
  };

  const saveInvoice = (values, resetForm) => {
    setSubmitting(true);
    let additionalLine;
    const content = values.content.map((line, index) => {
      if (line.hasMixVat) {
        const defaultVatPart = calcCreditToAmount(Math.abs(remainingCredit));

        additionalLine = {
          line: {
            ...line,
            vat: settings.defaultVat,
            amount: defaultVatPart,
          },
          index: index + 1,
        };
        return {
          ...line,
          vat: settings.reducedVat,
          amount: line.amount - defaultVatPart,
        };
      }
      return {
        ...line,
        vat: line.vat / 100,
      };
    });
    if (additionalLine) {
      content.splice(additionalLine.index, 0, additionalLine.line);
    }
    const res = {
      ...values,
      leadId: lead.id,
      projectId,
      content,
      product: {
        projectName: project?.name,
        productName: lead?.product?.name,
        address: lead?.product?.address,
      },
      bankAccounts: selectedBankAccounts.map((account) => account.id),
      amount: leadFilesUIProps.newFile ? sumBy(values.content, "amount") : values.amount,
      amountPaidToDate:
        values.amountPaidToDate !== 0 && values.invoiceStatus === "PARTIALLY_PAID"
          ? values.amountPaidToDate
          : null,
      authorisationCode: lead?.eligibleReducedVat ? lead?.authorisationCode : "",
    };
    const clients = lead?.users?.map((user) => formatDisplayNameIntl(intl, user));
    if (lead.invoiceAddressType === "CUSTOM") {
      res.to = {
        name: lead?.invoiceAddress?.name + "\n" + clients.join("\n"),
        address: lead?.invoiceAddress?.address,
      };
    } else {
      const clientAddress = lead.users.find((user) => user.id === lead.invoiceAddressType).address;
      res.to = {
        name: clients.join(",\n"),
        address: clientAddress,
      };
    }
    res.language = intl.locale;
    if (leadFilesUIContext.newFile) {
      dispatch(actions.createInvoice(res))
        .then(async () => {
          await dispatch(
            actions.updateLeadFieldLocally(
              "remainingCredit",
              remainingCredit < 0 ? 0 : remainingCredit
            )
          );
          await leadFilesUIProps.closeInvoiceFileDialog();
          resetForm();
          setSelectedBankAccounts([]);
        })
        .finally(() => setSubmitting(false));
    } else {
      dispatch(actions.updateFile(res))
        .then(() => {
          leadFilesUIProps.closeInvoiceFileDialog();
          resetForm();
          setSelectedBankAccounts([]);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const typeaheadRef = useRef(null);

  const [selectedBankAccounts, setSelectedBankAccounts] = useState([]);
  const updateSelectedBankAccounts = (bankAccounts) => {
    setSelectedBankAccounts(bankAccounts);
  };

  const reducedVatAvailable = useMemo(
    () =>
      lead?.eligibleReducedVat &&
      lead?.authorisationCode &&
      new Date(lead?.authorisationDate) < new Date(),
    [lead]
  );

  const handleLineChange = (lines = [], setFieldValue) => {
    if (lead?.remainingCredit && reducedVatAvailable) {
      let credit = 0;
      const res = cloneDeep(lines);
      for (const [index, line] of res.entries()) {
        delete line.hasMixVat;
        if (
          lineIsInstalment(line) &&
          (line.vat === settings.reducedVat * 100 || line.vat === settings.defaultVat * 100)
        ) {
          line.vat = settings.reducedVat * 100;
          credit += calcLineCredit(line);
          if (credit > lead?.remainingCredit) {
            line.hasMixVat = true;
            for (let i = index + 1; i < res.length; i++) {
              const nextLine = res[i];
              if (lineIsInstalment(nextLine) && nextLine.vat === settings.reducedVat * 100) {
                delete nextLine.hasMixVat;
                nextLine.vat = settings.defaultVat * 100;
              }
            }
            setFieldValue("content", res);
            break;
          }
          setFieldValue("content", res);
        }
      }
      setRemainingCredit(lead.remainingCredit - credit);
    }
  };

  const lineIsInstalment = (line) =>
    line.budgetInstalmentId &&
    !["SHARE_OF_LAND", "ARCHITECT_ENGINEERING_FEES"].includes(line.budgetInstalmentId);

  const calcCreditToAmount = (amount) => amount / (settings.defaultVat - settings.reducedVat);

  const calcLineCredit = (line) =>
    line.amount * settings.defaultVat - line.amount * settings.reducedVat;

  const shouldUseReducedVat = (lines, lineIndex, line) =>
    lineIsInstalment(line) &&
    reducedVatAvailable &&
    lead.remainingCredit !== 0 &&
    remainingCredit + calcLineCredit(line) > 0;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={InvoiceSchema}
      onSubmit={(values, { resetForm }) => saveInvoice(values, resetForm)}
    >
      {({ setFieldValue, values, submitForm, resetForm }) => (
        <Modal
          show={leadFilesUIProps.showInvoiceDialog}
          onHide={leadFilesUIProps.closeInvoiceFileDialog}
          aria-labelledby="new-invoice-modal"
          size={"lg"}
          scrollable
          centered
          backdrop="static"
        >
          {isLoading && <ModalProgressBar variant="query" />}
          <Modal.Header closeButton>
            <Modal.Title id="new-invoice-modal">
              <FormattedMessage
                id={leadFilesUIProps.newFile ? "INVOICE.TITLE.CREATE" : "INVOICE.TITLE.UPDATE"}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="form form-label-right">
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-8 mb-4">
                    <Field
                      name="friendlyName"
                      data-cy="title-invoice-form"
                      component={Input}
                      // onChange={(e) => customHandleChange(e)(handleChange)}
                      placeholder={intl.formatMessage({
                        id: "INVOICE.TITLE.TITLE",
                      })}
                      label={intl.formatMessage({
                        id: "INVOICE.TITLE.TITLE",
                      })}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      name="invoiceStatus"
                      label={intl.formatMessage({
                        id: "COMMON.STATUS",
                      })}
                    >
                      {Object.entries(INVOICE_STATUS).map(([key, val]) => (
                        <option key={key} value={key}>
                          {intl.formatMessage({ id: val })}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                {values?.invoiceStatus !== "PAID" && (
                  <div className="row justify-content-end">
                    <div className="col-lg-4">
                      <div>
                        <FormattedMessage id="TASK.LABEL.DATE.DUE" />
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <Field
                            name="dueDate"
                            showTimeSelect={false}
                            placeholderText={DateUtils.format(new Date(), intl, false)}
                            component={DatePickerField}
                            withFeedbackLabel={false}
                            dateFormat={"dd/MM/yyyy"}
                            onChange={(e) => {
                              const x = new Date().getTimezoneOffset() * 60000;
                              const localISOTime = new Date(e - x).toISOString().slice(0, -1);
                              return setFieldValue("dueDate", localISOTime);
                            }}
                          />
                          <span
                            className="datepicker-close-button ml-2"
                            onClick={() => {
                              setFieldValue("dueDate", "");
                            }}
                          >
                            <i className="fas fa-times" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {values?.invoiceStatus === "PARTIALLY_PAID" && (
                  <div className="row justify-content-end">
                    <div className="col-lg-4">
                      <div>
                        <FormattedMessage id="TASK.LABEL.PAID_TO_DATE" />
                      </div>
                      <NumberInput
                        suffix={" €"}
                        name="amountPaidToDate"
                        className="form-control text-right"
                        value={values.amountPaidToDate}
                        onValueChange={(e) => {
                          handleValueChange(
                            "amountPaidToDate",
                            values,
                            e.floatValue ?? 0,
                            setFieldValue
                          );
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {leadFilesUIProps.newFile && (
                <div className={"form-group form-row"}>
                  <div className={"col-12"}>
                    <label>
                      <FormattedMessage id={"SETTING.BANK.ACCOUNT"} />
                    </label>
                    <Typeahead
                      id="invoice-bank-accounts-typeahead-input"
                      ref={typeaheadRef}
                      paginate={false}
                      multiple
                      positionFixed
                      labelKey={"name"}
                      filterBy={["name", "IBAN", "BIC"]}
                      selected={selectedBankAccounts}
                      onChange={(selected) => {
                        updateSelectedBankAccounts(selected);
                      }}
                      options={settings?.bankAccounts ?? []}
                      placeholder={intl.formatMessage({ id: "COMMON.SELECT_BANK_ACCOUNT" })}
                      disabled={isLoading}
                      className={"flex-grow-1"}
                      renderMenu={(accounts, menuProps) => (
                        <Menu {...menuProps}>
                          {accounts.map((account, index) => (
                            <MenuItem option={account} position={index} key={index}>
                              <span className={"font-weight-bold"}>
                                <Highlighter
                                  search={typeaheadRef?.current?.state?.text ?? ""}
                                  highlightClassName={"bg-light-primary p-0"}
                                >
                                  {account.name}
                                </Highlighter>
                              </span>
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    />
                  </div>
                </div>
              )}
              {leadFilesUIProps.newFile && settings && (
                <>
                  {reducedVatAvailable && (
                    <>
                      <div className={"mb-4"}>
                        <span className="svg-icon svg-icon-lg svg-icon-info mr-2">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />
                        </span>
                        <FormattedMessage
                          id={"LEAD.CLIENT_ELIGIBLE_REDUCED_VAT"}
                          values={{ count: lead.users.length }}
                        />
                      </div>
                      <div className={"d-flex flex-wrap mb-2"}>
                        <div className={"d-flex mb-1 mr-10"}>
                          <div className={"d-flex flex-column"}>
                            <span>
                              <FormattedMessage id={"LEAD.AVAILABLE_CREDIT"} />
                            </span>
                            <span>
                              <FormattedMessage id={"LEAD.REMAINING_CREDIT_AFTER_INVOICE"} />
                            </span>
                          </div>
                          <div className={"d-flex flex-column"}>
                            <span className={"ml-2 font-weight-bold"}>
                              <NumberInput
                                value={lead.remainingCredit}
                                displayType="text"
                                suffix={" €"}
                                decimalScale={2}
                              />
                            </span>
                            <span className={"ml-2 font-weight-bold"}>
                              <NumberInput
                                value={remainingCredit < 0 ? 0 : remainingCredit}
                                displayType="text"
                                suffix={" €"}
                                decimalScale={2}
                              />
                            </span>
                          </div>
                        </div>
                        <div className={"d-flex mb-1"}>
                          <div className={"d-flex flex-column"}>
                            <span>
                              <FormattedMessage id={"LEAD.CONSTRUCTION_PRICE_WITH_REDUCED_VAT"} />
                            </span>
                            <span>
                              <FormattedMessage
                                id={"LEAD.REMAINING_CONSTRUCTION_PRICE_WITH_REDUCED_VAT"}
                              />
                            </span>
                          </div>
                          <div className={"d-flex flex-column"}>
                            <span className={"ml-2 font-weight-bold"}>
                              <NumberInput
                                value={lead.constructionPriceWithReducedVat}
                                displayType="text"
                                suffix={" €"}
                                decimalScale={2}
                              />
                            </span>
                            <span className={"ml-2 font-weight-bold"}>
                              <NumberInput
                                value={
                                  remainingCredit < 0
                                    ? 0
                                    : remainingCredit / (settings.defaultVat - settings.reducedVat)
                                }
                                displayType="text"
                                suffix={" €"}
                                decimalScale={2}
                              />
                            </span>
                          </div>
                        </div>
                        <div className={"d-flex mb-1 mr-10"}>
                          <div className={"d-flex flex-column"}>
                            <span>
                              <FormattedMessage id={"LEAD.AUTHORISATION_CODE"} />
                            </span>
                            <span>
                              <FormattedMessage id={"LEAD.AUTHORISATION_DATE"} />
                            </span>
                          </div>
                          <div className={"d-flex flex-column"}>
                            <span className={"ml-2 font-weight-bold"}>
                              {lead.authorisationCode}
                            </span>
                            <span className={"ml-2 font-weight-bold"}>
                              {DateUtils.format(lead.authorisationDate, intl, false)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <CustomCard
                    header={
                      <div className="form-row flex-grow-1 mr-12">
                        <div className="col-8 d-flex">
                          <h4>
                            <FormattedMessage id="INVOICE.TITLE.LINES" />
                          </h4>
                        </div>
                        <div className="col-1" />
                        <div className="col-3 text-right d-flex align-items-center justify-content-end">
                          <FormattedMessage id="INVOICE.TOTAL" />:
                          <NumberInput
                            value={sumBy(values.content, "amount")}
                            suffix={" €"}
                            decimalScale={2}
                            displayType="text"
                            className={"font-weight-bold ml-2"}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div className="d-flex">
                      <div className="w-15px" />
                      <div
                        className="form-row flex-grow-1 px-4 pt-2 font-size-xs font-weight-bold line-height-sm"
                        style={{ color: "#6a6a6a" }}
                      >
                        <div className="col-5 col-sm-8">
                          <FormattedMessage id="COMMON.DESCRIPTION" />
                        </div>
                        <div className="col-2 col-sm-1">
                          <FormattedMessage id="COMMON.VAT" />
                        </div>
                        <div className="col-5 col-sm-3">
                          <FormattedMessage id="COMMON.AMOUNT" />
                        </div>
                      </div>
                      <div className="w-30px ml-4" />
                    </div>
                    <FieldArray
                      name="content"
                      render={(linesArrayHelpers) => (
                        <>
                          <ReactSortable
                            list={values?.content}
                            setList={(sortedLines) => moveLine(values, sortedLines, setFieldValue)}
                            swapThreshold={0.65}
                            animation={150}
                            fallbackOnBody={true}
                            handle=".line-handle"
                            forceAutoScrollFallback={true}
                            scroll={true}
                            bubbleScroll={true}
                          >
                            {values.content?.map((line, lineIndex) => (
                              <CustomCardLine
                                removable={values.content.length !== 1}
                                draggable={true}
                                remove={(e) => linesArrayHelpers.remove(lineIndex)}
                                key={lineIndex}
                              >
                                <div className="form-row flex-grow-1">
                                  <div className="col-5 col-sm-8 d-flex align-items-center">
                                    <div className="flex-grow-1">
                                      {!isBudgetAvailable ? (
                                        <Field
                                          className="form-control form-control-sm"
                                          name="line.label"
                                          component={Input}
                                          value={line.label}
                                          onChange={(e) =>
                                            handleLineValueChange(
                                              "description",
                                              values,
                                              e.target.value,
                                              lineIndex,
                                              setFieldValue
                                            )
                                          }
                                        />
                                      ) : (
                                        <CreatableSelect
                                          name={`content.${lineIndex}.budgetInstalmentId`}
                                          isSearchable
                                          isClearable
                                          onChange={(selected) =>
                                            changeLine(selected, lineIndex, setFieldValue, values)
                                          }
                                          options={budgetInstalments}
                                          value={
                                            line.label
                                              ? {
                                                  id: line.budgetInstalmentId,
                                                  label: line.label,
                                                }
                                              : null
                                          }
                                          getOptionValue={(option) => option.id}
                                          getOptionLabel={(option) => option.label}
                                          placeholder={intl.formatMessage({
                                            id: "INVOICE.SELECT.LINE",
                                          })}
                                          classNamePrefix="creatable-select-sm"
                                          menuPosition="fixed"
                                          styles={{
                                            container: (provided, state) => ({
                                              ...provided,
                                              width: "100%",
                                            }),
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-2 col-sm-1 d-flex">
                                    <NumberInput
                                      name="vat"
                                      className="form-control form-control-sm text-right"
                                      suffix={" %"}
                                      decimalScale={0}
                                      value={line.vat}
                                      onValueChange={(e) =>
                                        handleLineValueChange(
                                          "vat",
                                          values,
                                          e.floatValue,
                                          lineIndex,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-5 col-sm-3 d-flex align-items-center">
                                    <NumberInput
                                      suffix={" €"}
                                      className="form-control form-control-sm text-right"
                                      value={line.amount}
                                      onValueChange={(e) =>
                                        handleLineValueChange(
                                          "amount",
                                          values,
                                          e.floatValue,
                                          lineIndex,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    {line.hasMixVat && (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id="layout-tooltip"
                                            className={"tooltip-auto-width"}
                                          >
                                            <FormattedMessage
                                              id={"LEAD.LINE_SPLIT_DIFFERENT_VAT"}
                                            />
                                          </Tooltip>
                                        }
                                      >
                                        <span className="svg-icon svg-icon-lg svg-icon-warning ml-2">
                                          <SVG
                                            src={toAbsoluteUrl(
                                              "/media/svg/icons/Code/Info-circle.svg"
                                            )}
                                          />
                                        </span>
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                </div>
                              </CustomCardLine>
                            ))}
                          </ReactSortable>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
                              onClick={(e) =>
                                linesArrayHelpers.push({
                                  id: uuid(),
                                  budgetInstalmentId: undefined,
                                  label: "",
                                  amount: 0,
                                  vat: (settings.defaultVat ?? 0) * 100,
                                })
                              }
                            >
                              <i className="ki ki-plus icon-nm" />
                              <FormattedMessage id="LEAD.ACTION.ADD.LINE" />
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </CustomCard>
                </>
              )}

              {(isOwner(session, values) ||
                leadFilesUIProps.newFile ||
                canReadAllDocument(groups, session, "LEAD")) && (
                <Checkbox name={"isConfidential"} label={"COMMON.CONFIDENTIAL.DOCUMENT"} />
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={() => cancelInvoice(resetForm)}
                className="btn btn-light btn-elevate mr-4"
                disabled={submitting}
              >
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <button
                type="button"
                onClick={submitForm}
                className="btn btn-primary btn-elevate"
                disabled={submitting}
              >
                {leadFilesUIContext.newFile ? (
                  <FormattedMessage id="COMMON.ACTION.CREATE" />
                ) : (
                  <FormattedMessage id="COMMON.ACTION.SAVE" />
                )}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}
