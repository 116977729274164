import * as requestFromServer from "./leadsCrud";
import { callTypes, leadsSlice } from "./leadsSlice";
import { usersSlice } from "../../../UsersManagement/_redux/usersSlice";

import { getProductById } from "../products/productsCrud";
import { getProjectById } from "../projects/projectsCrud";
import { getById } from "../../../UsersManagement/_redux/usersCrud";
import {
  processSackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../../Common/SnackbarNotificationsHandler";
import { save, saved } from "../../../../../redux/global/globalActions";
import { CLIENT } from "../../../../_utils/userTypes";
import { requestFileSignature } from "../../../../_utils/signatureUtils";
import { productsSlice } from "../products/productsSlice";
import { projectsSlice } from "../projects/projectsSlice";

const contextualActions = {
  default: leadsSlice("leads").actions,
  productLeads: leadsSlice("productLeads").actions,
  projectLeads: leadsSlice("projectLeads").actions,
  clientLeads: leadsSlice("clientLeads").actions,
  leadClients: usersSlice("leadClients").actions,
};

const contextualActionProduct = {
  default: productsSlice("products").actions,
};

const { actions: actionProject } = projectsSlice;

const actions = contextualActions.default;
const actionClientLeads = contextualActions.leadClients;
const actionProduct = contextualActionProduct.default;

export const fetchLeads =
  (queryParams = {}, context = "default") =>
  (dispatch) => {
    dispatch(contextualActions[context].startCall({ callType: callTypes.list }));
    return requestFromServer
      .findLeads(queryParams)
      .then((response) => {
        dispatch(contextualActions[context].leadsFetched({ entities: response }));
      })
      .catch((error) => {
        // error.displayMessage = "Can't find leads";
        dispatch(contextualActions[context].catchError({ error, callType: callTypes.list }));
        processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_LEAD, dispatch);
      });
  };

export const fetchLeadComplete = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.leadFetched({ leadForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLeadComplete(id)
    .then((response) => {
      const leadForEdit = response;
      dispatch(actions.leadFetched({ leadForEdit }));
      dispatch(actionClientLeads.usersFetched({ entities: response.users }));
    })
    .catch((error) => {
      // error.displayMessage = "Can't find lead";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_LEAD, dispatch);
    });
};

export const fetchLead = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.leadFetched({ leadForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLeadById(id)
    .then((response) => {
      const leadForEdit = response;
      console.log("fetchLead leadForEdit: ", leadForEdit);
      dispatch(actions.leadFetched({ leadForEdit }));
      dispatch(actionClientLeads.usersFetched({ entities: response.users }));
    })
    .catch((error) => {
      // error.displayMessage = "Can't find lead";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_LEAD, dispatch);
    });
};

export const fetchLeadDetails = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.leadFetched({ leadForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLeadComplete(id)
    .then((response) => {
      const leadForEdit = response;
      dispatch(actions.leadFetched({ leadForEdit }));
      dispatch(actionClientLeads.usersFetched({ entities: response.users }));
      dispatch(actionProduct.productFetched({ productForEdit: response.product }));
      dispatch(actionProject.projectFetched({ projectForEdit: response.project }));
    })
    .catch((error) => {
      // error.displayMessage = "Can't find lead";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_LEAD, dispatch);
    });
};

export const deleteLead =
  ({ context, id }) =>
  (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .deleteLead(id)
      .then((response) => {
        processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_LEAD, dispatch);

        dispatch(actions.leadDeleted({ id }));
        if (context) {
          dispatch(contextualActions[context].leadDeleted({ id }));
        }
      })
      .catch((error) => {
        // error.displayMessage = "Can't delete lead";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_LEAD, dispatch);
      });
  };

export const createLead = (leadForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLead(leadForCreation)
    .then((response) => {
      console.log("createLead response: ", response);
      const lead = { ...leadForCreation, ...response };
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_LEAD, dispatch);

      dispatch(actions.leadCreated({ lead }));
      return lead;
    })
    .catch((error) => {
      // error.displayMessage = "Can't create lead";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_LEAD, dispatch);
    });
};

export const updateLeadFieldLocally = (key, value) => (dispatch) => {
  dispatch(actions.leadFieldUpdatedLocally({ key, value }));
};

export const updateLead = (leadForEdit) => (dispatch) => {
  const requestBody = { ...leadForEdit };
  delete requestBody.product;
  dispatch(save());
  return requestFromServer
    .updateLead(requestBody)
    .then((response) => {
      console.log("updateLead response: ", response);
      const lead = { ...leadForEdit, ...response };
      dispatch(actions.leadUpdated({ lead }));
      dispatch(saved());
    })
    .catch((error) => {
      dispatch(saved());
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_LEAD, dispatch);
    });
};

export const fetchLeadProduct = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.leadProductFetched({ product: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return getProductById(id)
    .then((response) => {
      const product = response;
      console.log("fetchLeadProduct product: ", product);
      dispatch(actions.leadProductFetched({ product }));
      return product;
    })
    .catch((error) => {
      // error.displayMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PRODUCT, dispatch);
    });
};

export const fetchLeadClients = (ids, catchEmpty) => (dispatch) => {
  if (!ids) {
    return dispatch(actions.leadClientsFetched({ users: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return Promise.all(ids.map((id) => getById(id, CLIENT)))
    .then((responses) => {
      const clientsFromDb = responses.filter(
        (client) => !!client.id && client.userTypes?.includes(CLIENT)
      );
      if (clientsFromDb.length === 0) {
        catchEmpty();
        throw new Error("Failed to find clients");
      } else {
        dispatch(actions.leadClientsFetched({ users: clientsFromDb }));
        dispatch(actionClientLeads.usersFetched({ entities: clientsFromDb }));
      }
    })
    .catch((error) => {
      // error.displayMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_CLIENTS, dispatch);
    });
};

export const fetchProject = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return getProjectById(id)
    .then((response) => {
      const project = response;
      console.log("getProjectById product: ", project);
      dispatch(actions.projectFetched({ project }));
      return project;
    })
    .catch((error) => {
      // error.displayMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_PRODUCT, dispatch);
    });
};

export const uploadFile = (fileForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createFile(fileForCreation)
    .then((response) => {
      const file = { ...response };
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPLOAD_FILE, dispatch);

      dispatch(actions.fileCreated({ file }));
    })
    .catch((error) => {
      // error.displayMessage = "Can't create file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.ADD_FILE, dispatch);
    });
};

export const updateFile = (fileForEdit) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateFile(fileForEdit)
    .then((response) => {
      const file = { ...response };
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_FILE, dispatch);

      dispatch(actions.fileUpdated({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_FILE, dispatch);
    });
};

export const openFile = (file) => (dispatch) => {
  // Open it before the async call because iOS is blocking it
  const newWindow = window.open("", "_blank");
  return requestFromServer
    .getFile(file)
    .then((response) => {
      newWindow.location = response.url;
    })
    .catch((error) => {
      newWindow.close();
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.OPEN_FILE, dispatch);
    });
};

export const deleteFile = (file) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFile(file)
    .then((response) => {
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_FILE, dispatch);
      if (response?.remainingCredit) {
        dispatch(updateLeadFieldLocally("remainingCredit", response.remainingCredit));
      }
      dispatch(actions.fileDeleted({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_FILE, dispatch);
    });
};

export const createInvoice = (invoice) => (dispatch) => {
  dispatch(actions.startCall({ callTypes: callTypes.action }));
  return requestFromServer
    .createInvoice(invoice)
    .then((file) => {
      processSackbarNotification(SNACKBAR_MESSAGE.SUCCESS.CREATE_INVOICE, dispatch);

      dispatch(actions.fileCreated({ file }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.CREATE_INVOICE, dispatch);
    });
};

export const fetchAuditTrail = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.auditTrailFetched({ events: undefined }));
  }
  console.log("fetchAuditTrail...");
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAuditTrail(id)
    .then((response) => {
      dispatch(actions.auditTrailFetched({ events: response }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      processSackbarNotification(SNACKBAR_MESSAGE.DANGER.LOAD_AUDIT_TRAIL, dispatch);
    });
};

export const requestLeadFileSignature = (signatureRequest) => (dispatch) => {
  return requestFileSignature(actions, signatureRequest, dispatch, callTypes);
};
