// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../../_metronic/_partials/controls";
import { Switch } from "@material-ui/core";
import { cloneDeep, get } from "lodash-es";
import { SUBCONTRACTOR } from "../../../../../../_utils/userTypes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../PropertiesManagement/_redux/projects/projectsActions";
import * as budgetActions from "../../../../../Budget/_redux/budgetsActions";
import { NumberInput } from "../../../../../../_utils/formUtils";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomCard, CustomCardLine } from "../../../../../Common/CustomCard";
import { ReactSortable } from "react-sortablejs";
import {
  canEdit,
  canReadAllUserDocument,
  isAdmin,
  isOwner,
} from "../../../../../../_utils/authUtils";
import { USER_FILE_TYPES } from "../../../../../../_utils/listUtils";
import { isBudgetServiceAvailable } from "../../../../../../_utils/configUtils";
import { Checkbox } from "../../../../../../_components/Checkbox";
import { v4 as uuid } from "uuid";
import { fetchSettings } from "../../../../../Settings/_redux/settingsActions";
import logout from "../../../../../Auth/pages/Logout";
import { isFinanceFile } from "../../../../../../../data/schemas";
import { FileDropzone } from "../../../../../../_components/FileDropzone";

const initialValueCategories = [{ lines: [{ id: uuid(), vat: 0, amount: 0 }] }];
export function UserFileEditForm({
  saveFile,
  saveMultipleFiles,
  file,
  actionsLoading,
  onHide,
  isUploadingFile,
  readOnly,
  userTypes,
}) {
  const intl = useIntl();

  // Validation schema
  const FileEditSchema = Yup.object().shape({
    friendlyName: Yup.string()
      .min(2, `${intl.formatMessage({ id: "AUTH.VALIDATION.MIN_SYMBOLS" })} 2`)
      .max(200, `${intl.formatMessage({ id: "AUTH.VALIDATION.MAX_SYMBOLS" })} 200`)
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.FILE.NAME.REQUIRED" })),
    fileType: Yup.string().required(intl.formatMessage({ id: "AUTH.VALIDATION.TYPE.REQUIRED" })),
    userType: Yup.string(),
    projectId: Yup.string(),
    categories: Yup.array().of(
      Yup.object().shape({
        budgetLineCategoryId: Yup.string(),
        budgetLineCategoryLabel: Yup.string(),
        lines: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            budgetLineId: Yup.string(),
            budgetLineLabel: Yup.string(),
            vat: Yup.number(),
            amount: Yup.number(),
          })
        ),
      })
    ),
    visible: Yup.bool(),
    notifyUser: Yup.bool(),
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(file?.fileType ?? null);
  const [rectoVerso, setRectoVerso] = useState(false);
  const [project, setProject] = useState();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);

  const { projectsListLoading, projects, budget, session, groups, entityForEdit, settings } =
    useSelector(
      (state) => ({
        projectsListLoading: state.projects.listLoading,
        projects: state.projects.entities,
        budgetLoading: state.budgets.actionsLoading,
        budget: state.budgets?.budgetForEdit?.saved,
        session: state.auth.session,
        groups: state.auth.groups,
        entityForEdit: state.users.entityForEdit.current
          ? state.users.entityForEdit.current
          : state?.users?.entities?.find((entity) => entity?.id === file.userId),
        settings: state.settings.settingsForEdit.saved,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (!settings) {
      dispatch(fetchSettings(() => logout()));
    }
  }, [settings]);

  useEffect(() => {
    if (
      session &&
      groups &&
      !project &&
      canEdit(groups, session, "SUBCONTRACTOR") &&
      formRef?.current?.values?.userType === SUBCONTRACTOR
    ) {
      dispatch(actions.fetchProjects()).then((projects) => {
        if (file.projectId) {
          setProject(projects.find((project) => project.id === file.projectId));
        } else {
          setProject(projects[0]);
        }
      });
    }
  }, [session, groups, formRef?.current?.values?.userType]);

  useEffect(() => {
    if (isBudgetAvailable && isFinanceFile(formRef.current.values.fileType)) {
      dispatch(budgetActions.fetchBudget(project?.selectedBudget));
    }
  }, [project]);

  const [fileTypes, setFileTypes] = useState({});

  useEffect(() => {
    setFileTypes(USER_FILE_TYPES[formRef?.current?.values?.userType]);
  }, [formRef?.current?.values?.userType]);

  const onProjectChange = (e, handleChange, setFieldValue) => {
    handleChange(e);
    if (project && e.target.value !== project.id) {
      setFieldValue("categories", [
        { lines: [{ id: uuid(), vat: settings.defaultVat, amount: 0 }] },
      ]);
    }
    setProject(projects.find((project) => project.id === e.target.value));
  };

  const onUploadAsChange = (e, handleChange) => {
    handleChange(e);
    setFileTypes(USER_FILE_TYPES[e.target.value]);
  };

  const onFileTypeChange = (e, handleChange, setFieldValue, values) => {
    setSelectedFileType(e.target.value !== "-" ? e.target.value : null);
    handleChange(e);
    if (
      isFinanceFile(e.target.value) &&
      values.userType === SUBCONTRACTOR &&
      canEdit(groups, session, "USER")
    ) {
      if (!values.projectId) {
        setFieldValue("projectId", projects[0]?.id);
        setProject(projects[0]);
        if (isBudgetAvailable) {
          dispatch(budgetActions.fetchBudget(projects[0]?.selectedBudget));
        }
      } else {
        if (isBudgetAvailable) {
          dispatch(budgetActions.fetchBudget(project?.selectedBudget));
        }
      }
    }
  };

  const changeBudgetCategory = (values, selected, setFieldValue, categoryIndex) => {
    setFieldValue(`categories.${categoryIndex}.budgetLineCategoryId`, selected?.id ?? "");
    setFieldValue(`categories.${categoryIndex}.budgetLineCategoryLabel`, selected?.label);
    if (selected?.id) {
      const budgetLine = budget.sortedLines.find((cat) => cat.id === selected.id).lines[0];
      setFieldValue(`categories.${categoryIndex}.lines`, [
        {
          id: uuid(),
          budgetLineId: budgetLine.id,
          budgetLineLabel: budgetLine.label,
          vat: settings.defaultVat,
          amount: 0,
        },
      ]);
      if (!selected.visible) {
        setFieldValue("visible", false);
      }
    } else {
      for (const lineIndex in values.categories[categoryIndex].lines) {
        setFieldValue(`categories.${categoryIndex}.lines.${lineIndex}.budgetLineId`, "");
      }
    }
  };

  const addCategory = (e, categoryArrayHelpers) => {
    e.preventDefault();
    categoryArrayHelpers.push({
      lines: [{ id: uuid(), vat: settings.defaultVat, amount: 0 }],
    });
  };

  const addLine = (e, lineArrayHelpers) => {
    e.preventDefault();
    lineArrayHelpers.push({
      id: uuid(),
      vat: settings.defaultVat,
      amount: 0,
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...file,
        categories: file.categories || initialValueCategories,
      }}
      validationSchema={FileEditSchema}
      onSubmit={(values) => {
        const filteredValues = {
          ...values,
          categories: values.categories.map(({ chosen, selected, ...category }) => ({
            ...category,
            lines: category.lines.map(({ chosen, selected, ...line }) => line),
          })),
        };

        // TODO: merge these files in one PDF
        if (selectedFileType === "ID" && !rectoVerso && !!selectedFile && !!selectedFile2) {
          saveMultipleFiles(
            {
              ...filteredValues,
              friendlyName: "[ID] Recto",
              file: selectedFile,
            },
            {
              ...filteredValues,
              friendlyName: "[ID] Verso",
              file: selectedFile2,
            }
          );
        } else {
          saveFile({
            ...filteredValues,
            file: selectedFile,
          });
        }
      }}
      innerRef={formRef}
    >
      {({ handleSubmit, setFieldValue, values, handleChange, handleBlur }) => (
        <>
          <Modal.Body className="overlay">
            {actionsLoading && (
              <div className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}
            <Form className="form form-label-right" data-cy="form-user-file">
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    data-cy="input-file-name"
                    name="friendlyName"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                    label={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                    disabled={
                      readOnly ||
                      (!isAdmin(groups, session) && file?.id
                        ? !isOwner({ id: file.userId }, file)
                        : false)
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                {userTypes.length > 1 && ( // In case we are in profile
                  <div className="col-sm-6">
                    <Select
                      name="userType"
                      data-cy="select-file-upload-as"
                      label={intl.formatMessage({
                        id: "FILE.UPLOAD_AS",
                      })}
                      onChange={(e) => onUploadAsChange(e, handleChange)}
                    >
                      {userTypes.map((value) => (
                        <option key={value} value={value}>
                          {intl.formatMessage({ id: `${value}.TITLE.SINGLE` })}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className={userTypes.length > 1 ? "col-sm-6" : "col-12"}>
                  <Select
                    data-cy="select-file-type"
                    name="fileType"
                    label={intl.formatMessage({
                      id: "COMMON.TYPE",
                    })}
                    disabled={
                      file.fileType === "FILLOUT_AML_KYC_FORM" ||
                      readOnly ||
                      (!isAdmin(groups, session) && file?.id
                        ? !isOwner({ id: file.userId }, file)
                        : false)
                    }
                    onChange={(e) => onFileTypeChange(e, handleChange, setFieldValue, values)}
                  >
                    {file.fileType === "FILLOUT_AML_KYC_FORM" ? (
                      <option>
                        {intl.formatMessage({
                          id: USER_FILE_TYPES.CLIENT.FILLOUT_AML_KYC_FORM,
                        })}
                      </option>
                    ) : (
                      fileTypes &&
                      Object.keys(fileTypes)
                        .filter((fileType) => fileType !== "FILLOUT_AML_KYC_FORM")
                        .map((value) => (
                          <option key={value} value={value}>
                            {intl.formatMessage({ id: fileTypes[value] })}
                          </option>
                        ))
                    )}
                  </Select>
                </div>
              </div>
              {canEdit(groups, session, "USER") && // User Access check
                (isUploadingFile || file.id) && // Check if not request document dialog
                values.userType === SUBCONTRACTOR && ( // Check that user is a subcontractor
                  <>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        {projectsListLoading && (
                          <Spinner
                            animation="border"
                            variant="primary"
                            className="position-absolute"
                            style={{ top: "30px", left: "20px" }}
                          />
                        )}
                        <Select
                          data-cy="subcontractor-file-project-form"
                          name="projectId"
                          label="Project"
                          onChange={(e) => onProjectChange(e, handleChange, setFieldValue)}
                          value={values.projectId}
                        >
                          {!isFinanceFile(values.fileType) && <option value={"NONE"}>-</option>}
                          {projects.map((project, index) => (
                            <option key={index} value={project.id}>
                              {project.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {project && isFinanceFile(values.fileType) && (
                      <>
                        {project.selectedBudget ? (
                          <label>
                            <FormattedMessage id="BUDGET.ACTION.CHOOSE.CATEGORY_OR_LINE" />
                          </label>
                        ) : (
                          isBudgetAvailable && (
                            <label className="text-warning">
                              <FormattedMessage id="BUDGET.NOT.IN.PROJECT" />{" "}
                              <Link to={`/budgets/new?projectId=${project.id}`}>
                                <FormattedMessage id="BUDGET.ACTION.CREATE.BUDGET" />
                              </Link>{" "}
                              <FormattedMessage id="BUDGET.ACTION.OR.FILL.OUT.INFORMATION" />
                            </label>
                          )
                        )}
                        <FieldArray
                          name="categories"
                          validateOnChange={false}
                          render={(categoryArrayHelpers) => (
                            <>
                              <ReactSortable
                                list={cloneDeep(values.categories || [])}
                                setList={(sortedCategories) =>
                                  setFieldValue("categories", sortedCategories)
                                }
                                animation={150}
                                handle=".card-handle"
                                forceAutoScrollFallback={true}
                                scroll={true}
                                bubbleScroll={true}
                              >
                                {values.categories?.map((category, categoryIndex) => (
                                  <CustomCard
                                    draggable={true}
                                    remove={(e) => categoryArrayHelpers.remove(categoryIndex)}
                                    key={categoryIndex}
                                    header={
                                      <div className="form-row flex-grow-1 ">
                                        <div className="col-8 d-flex">
                                          {!isBudgetAvailable ? (
                                            <Field
                                              className="form-control form-control-sm"
                                              name="budgetLineCategoryLabel"
                                              component={Input}
                                              value={category.budgetLineCategoryLabel}
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `categories.${categoryIndex}.budgetLineCategoryLabel`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          ) : (
                                            <CreatableSelect
                                              name="budgetLineCategoryId"
                                              onChange={(selected) =>
                                                changeBudgetCategory(
                                                  values,
                                                  selected,
                                                  setFieldValue,
                                                  categoryIndex
                                                )
                                              }
                                              isSearchable
                                              isClearable
                                              options={budget?.sortedLines || []}
                                              value={
                                                category.budgetLineCategoryLabel
                                                  ? {
                                                      id: category.budgetLineCategoryId,
                                                      label: category.budgetLineCategoryLabel,
                                                    }
                                                  : null
                                              }
                                              getOptionValue={(option) => option.id}
                                              getOptionLabel={(option) => option.label}
                                              placeholder={intl.formatMessage({
                                                id: "BUDGET.SELECT_BUDGET_CATEGORY",
                                              })}
                                              classNamePrefix="creatable-select-sm"
                                              menuPosition="fixed"
                                              styles={{
                                                container: (provided, state) => ({
                                                  ...provided,
                                                  width: "100%",
                                                }),
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    }
                                  >
                                    <div>
                                      <div
                                        className="form-row px-4 pt-2 font-size-xs font-weight-bold line-height-sm"
                                        style={{ color: "#6a6a6a" }}
                                      >
                                        <div className="col-5 col-sm-8">
                                          <FormattedMessage id="COMMON.DESCRIPTION" />
                                        </div>
                                        <div className="col-2 col-sm-1">
                                          <FormattedMessage id="COMMON.VAT" />
                                        </div>
                                        <div className="col-5 col-sm-3">
                                          <FormattedMessage id="COMMON.AMOUNT" />
                                        </div>
                                      </div>
                                    </div>
                                    <FieldArray
                                      name={`categories.${categoryIndex}.lines`}
                                      // validateOnChange={false}
                                      render={(lineArrayHelpers) => (
                                        <>
                                          <ReactSortable
                                            list={cloneDeep(category?.lines || [])}
                                            setList={(sortedLines) =>
                                              setFieldValue(
                                                `categories.${categoryIndex}.lines`,
                                                sortedLines
                                              )
                                            }
                                            swapThreshold={0.65}
                                            animation={150}
                                            fallbackOnBody={true}
                                            group="nested"
                                            handle=".line-handle"
                                            forceAutoScrollFallback={true}
                                            scroll={true}
                                            bubbleScroll={true}
                                          >
                                            {category?.lines?.map((line, lineIndex) => (
                                              <CustomCardLine
                                                draggable={true}
                                                key={lineIndex}
                                                remove={(e) => lineArrayHelpers.remove(lineIndex)}
                                                removable={category.lines.length !== 1}
                                              >
                                                <div className="form-row flex-grow-1">
                                                  <div className="col-5 col-sm-8 d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                      {!isBudgetAvailable ? (
                                                        <Field
                                                          className="form-control form-control-sm"
                                                          name="line.budgetLineLabel"
                                                          component={Input}
                                                          value={line.budgetLineLabel}
                                                          onChange={(e) =>
                                                            setFieldValue(
                                                              `categories.${categoryIndex}.lines.${lineIndex}.budgetLineLabel`,
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <CreatableSelect
                                                          name="budgetLineId"
                                                          onChange={(selected) => {
                                                            setFieldValue(
                                                              `categories.${categoryIndex}.lines.${lineIndex}.budgetLineId`,
                                                              selected?.id ?? ""
                                                            );
                                                            setFieldValue(
                                                              `categories.${categoryIndex}.lines.${lineIndex}.budgetLineLabel`,
                                                              selected?.label
                                                            );
                                                          }}
                                                          isSearchable
                                                          isClearable
                                                          options={
                                                            budget?.sortedLines?.find(
                                                              (cat) =>
                                                                cat.id ===
                                                                category.budgetLineCategoryId
                                                            )?.lines || []
                                                          }
                                                          value={
                                                            line.budgetLineLabel
                                                              ? {
                                                                  id: line.budgetLineId,
                                                                  label: line.budgetLineLabel,
                                                                }
                                                              : null
                                                          }
                                                          getOptionValue={(option) => option.id}
                                                          getOptionLabel={(option) => option.label}
                                                          placeholder={intl.formatMessage({
                                                            id: "BUDGET.SELECT_BUDGET_LINE",
                                                          })}
                                                          classNamePrefix="creatable-select-sm"
                                                          menuPosition="fixed"
                                                          styles={{
                                                            container: (provided, state) => ({
                                                              ...provided,
                                                              width: "100%",
                                                            }),
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="col-2 col-sm-1 d-flex">
                                                    <NumberInput
                                                      className="form-control form-control-sm text-right"
                                                      suffix={" %"}
                                                      decimalScale={0}
                                                      value={line.vat * 100}
                                                      onValueChange={(e) =>
                                                        setFieldValue(
                                                          `categories.${categoryIndex}.lines.${lineIndex}.vat`,
                                                          e.floatValue / 100 || 0
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-5 col-sm-3 d-flex">
                                                    <NumberInput
                                                      suffix={" €"}
                                                      className="form-control form-control-sm text-right"
                                                      value={line.amount}
                                                      onValueChange={(e) =>
                                                        setFieldValue(
                                                          `categories.${categoryIndex}.lines.${lineIndex}.amount`,
                                                          e.floatValue || 0
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </CustomCardLine>
                                            ))}
                                          </ReactSortable>
                                          <div className="d-flex">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-light flex-grow-1 rounded-0 d-flex align-items-center  justify-content-center"
                                              onClick={(e) => addLine(e, lineArrayHelpers)}
                                            >
                                              <i className="ki ki-plus icon-nm" />
                                              <FormattedMessage id="BUDGET.ACTION.ADD.LINE" />
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    />
                                  </CustomCard>
                                ))}
                              </ReactSortable>
                              <button
                                type="button"
                                className="btn btn-light w-100 mt-2 mb-10 d-flex align-items-center justify-content-center"
                                onClick={(e) => addCategory(e, categoryArrayHelpers)}
                              >
                                <i className="ki ki-plus icon-nm" />
                                <FormattedMessage id="BUDGET.ACTION.CATEGORY.ADD" />
                              </button>
                            </>
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              {isUploadingFile && (selectedFileType || file.status === "REQUESTED") && (
                <div className="form-group row">
                  <div className="col">
                    {actionsLoading && !!selectedFile ? (
                      <div className="upload-file-container upload-file-container--disabled">
                        <div className="d-flex justify-content-center align-middle">
                          <div className="spinner-grow text-primary mr-4" />
                          <div>
                            <FormattedMessage id="FILE.ACTION.UPLOADING" />
                          </div>
                        </div>
                      </div>
                    ) : selectedFileType === "ID" ? (
                      <>
                        <div className="row">
                          <div className="col">
                            <Checkbox
                              name={"rectoVerso"}
                              label={"FILE.FRONT.BACK"}
                              onChange={() => setRectoVerso(!rectoVerso)}
                              checked={rectoVerso}
                              data-cy="checkbox-recto-verso"
                            />
                          </div>
                        </div>
                        {selectedFileType && rectoVerso ? (
                          <FileDropzone
                            values={values}
                            propsSetFieldValue={setFieldValue}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                          />
                        ) : (
                          <div className="row d-flex justify-content-around">
                            <div className="col-md-6 mb-5 mb-md-0">
                              <FileDropzone
                                values={values}
                                propsSetFieldValue={setFieldValue}
                                selectedFile={selectedFile}
                                setSelectedFile={setSelectedFile}
                                textField="FILE.FRONT"
                              />
                            </div>
                            <div className="col-md-6 mb-5 mb-md-0">
                              <FileDropzone
                                values={values}
                                propsSetFieldValue={setFieldValue}
                                selectedFile={selectedFile2}
                                setSelectedFile={setSelectedFile2}
                                textField="FILE.BACK"
                                inputName="file-back"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <FileDropzone
                        values={values}
                        propsSetFieldValue={setFieldValue}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                      />
                    )}
                  </div>
                </div>
              )}
              {!isUploadingFile &&
                !readOnly &&
                !file.id &&
                file.fileType !== "FILLOUT_AML_KYC_FORM" && (
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Switch
                        data-cy="switch-notify-user"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="notifyUser"
                        checked={!!get(values, "notifyUser")}
                      />
                      <FormattedMessage id="USER.ACTION.NOTIFY.SMS" />
                    </div>
                  </div>
                )}
              {(isOwner(session, values) ||
                !file.id ||
                canReadAllUserDocument(groups, session, entityForEdit)) && (
                <>
                  {isFinanceFile(values.fileType) && (
                    <>
                      <Checkbox
                        name={"selectedForFinance"}
                        label={"FILE.ACTION.SELECT_FOR_FINANCE_ANALYSIS"}
                        customClasses={"mb-2"}
                      />
                    </>
                  )}
                  <Checkbox name={"isConfidential"} label={"COMMON.CONFIDENTIAL.DOCUMENT"} />
                </>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              disabled={actionsLoading}
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              <FormattedMessage id="COMMON.ACTION.CANCEL" />
            </button>
            <button
              disabled={
                (isUploadingFile &&
                  (!selectedFile || (values.fileType === "ID" && !rectoVerso && !selectedFile2))) ||
                actionsLoading
              }
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
              data-cy="button-submit"
            >
              {!isUploadingFile && !readOnly && !file.id ? (
                <FormattedMessage id="COMMON.ACTION.REQUEST" />
              ) : (
                <FormattedMessage id="COMMON.ACTION.SAVE" />
              )}
            </button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
}
