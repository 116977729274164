import { Spinner } from "react-bootstrap";

import { Card, CardBody } from "_metronic/_partials/controls";

export const CardLoaderSpinner = () => {
  return (
    <Card>
      <CardBody>
        <div
          style={{ height: "40vh" }}
          className={"d-flex justify-content-center align-items-center"}
        >
          <Spinner animation="border" variant="primary" style={{ top: "30px", left: "20px" }} />
        </div>
      </CardBody>
    </Card>
  );
};
