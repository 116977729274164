import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "../../../../_metronic/_partials/controls";
import { API } from "@aws-amplify/api";
import { SignatureInformationModal } from "./SignatureInformationModal";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { PdfViewer } from "../../Common/PdfViewer/PdfViewer";
import { FormattedMessage, useIntl } from "react-intl";
import { DateUtils } from "../../../_utils/DateUtils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { OtpModalSignature } from "./OtpModalSignature";
import { downloadFileBase64 } from "../../UsersManagement/_redux/usersCrud";

export function PdfViewerPageSignature({ fileUrl, signature, setFileBase64 }) {
  const intl = useIntl();
  const [signatureInfo, setSignatureInfo] = useState(signature);
  const [modalState, setModalState] = useState("");

  useEffect(() => {
    if (signatureInfo?.id && !signatureInfo?.signedDate) {
      API.get("API", `/signatures/${signatureInfo.id}`, {}).then((response) => {
        setSignatureInfo({ ...signature, ...response });
        downloadFileBase64(response?.fileUrl).then((res) => setFileBase64(res));
      });
    }
  }, [signature]);
  const validateSignatureInfoModal = () => {
    setModalState("OTP");
    resendCode();
  };
  const resendCode = () => {
    console.log("resendCode");
    API.post("API", `/signatures/${signatureInfo.id}/otp`, {}).then((response) =>
      console.log(response)
    );
  };

  return (
    <>
      {signature.id && (
        <>
          <SignatureInformationModal
            show={modalState === "INFO"}
            onHide={() => setModalState("")}
            validateSignatureModal={validateSignatureInfoModal}
          />
          <OtpModalSignature
            show={modalState === "OTP"}
            onHide={() => setModalState("")}
            resendCode={resendCode}
            id={signature?.id}
            setFileBase64={setFileBase64}
          />
        </>
      )}
      <Card className={"h-100"}>
        <CardHeader title={intl.formatMessage({ id: "SIGN.TITLE" })} />
        <CardBody
          className={"p-0 d-flex align-items-center justify-content-center position-relative"}
          style={{ minHeight: "40vh" }}
        >
          <div className={"w-100 h-100 position-absolute"}>
            <PdfViewer fileUrl={fileUrl} />
          </div>
        </CardBody>
        <CardFooter className={"p-4 p-sm-6"}>
          <div className={"text-right"} data-cy="text-signature-signed-confirmation">
            {signatureInfo?.signedDate ? (
              <>
                <span className="svg-icon svg-icon-2x svg-icon-success mr-1">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")} />
                </span>
                <FormattedMessage
                  id="SIGN.SIGNED_AT"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                    date: DateUtils.format(new Date(signatureInfo.signedDate), intl, true),
                    user: signatureInfo.signedBy,
                  }}
                />
              </>
            ) : (
              <button
                data-cy="button-signature-sign"
                type="button"
                className="btn btn-primary"
                onClick={() => setModalState("INFO")}
                disabled={!signatureInfo?.id}
              >
                <FormattedMessage id={"SIGN.ACTION"} />
              </button>
            )}
          </div>
        </CardFooter>
      </Card>
    </>
  );
}
