import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { updateUser } from "../../UsersManagement/_redux/usersActions";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { fetchSettings } from "../../Settings/_redux/settingsActions";
import { useHistory } from "react-router-dom";

function AcceptGDPR(props) {
  const [agreeGDPR, setAgreeGDPR] = useState(false);
  const [agreeMobile, setAgreeMobile] = useState(false);
  const [agreeEmail, setAgreeEmail] = useState(false);
  const dispatch = useDispatch();
  const { session, settings } = useSelector((state) => ({
    session: state.auth.session,
    settings: state.settings.settingsForEdit.saved,
  }));

  useEffect(() => {
    if (!settings) {
      dispatch(fetchSettings(() => logout()));
    }
  }, [settings]);

  const acceptGDPR = () => {
    if (agreeGDPR && agreeMobile && agreeEmail) {
      const gdprApprovedAt = new Date();
      dispatch(updateUser({ ...session, gdprApprovedAt })).then((user) => {
        props.acceptGDPR(gdprApprovedAt);
      });
    }
  };

  const history = useHistory();
  const logout = () => {
    history.push("/logout");
  };

  return (
    <>
      {/*

      Note: company-settings must be configured before giving access to customers.
      Missing settings/settings-object causes the UI to remain in loading state.

      Company details/settings can be configured by accessing the UI using an admin account
      (e.g. master@immotool.lu) in settings tab (wheel icon in overlay menu on left side next
      to autosave icon)

      */}
      {settings && session ? (
        <div className="mx-8" style={{ maxWidth: "550px" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.GDPR.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.GDPR.SUBTITLE" />
            </p>
          </div>
          <div className={"font-size-lg font-weight-bold mb-4"}>
            <FormattedMessage
              id="AUTH.GDPR.TERMS_PRIVACY.TITLE"
              values={{ companyName: settings?.companyName }}
            />
          </div>
          <div className="d-flex align-items-center flex-grow-1 mb-4">
            <label className="checkbox align-items-start">
              <input
                type="checkbox"
                checked={agreeGDPR}
                onChange={() => setAgreeGDPR(!agreeGDPR)}
              />
              <span className="mr-4 flex-shrink-0" id="checkbox-privacy-policy" />
              <div>
                <FormattedMessage
                  id={"AUTH.GDPR.TERMS_PRIVACY"}
                  values={{
                    termsLink: (chunks) => (
                      <a
                        className="text-primary"
                        href={settings?.termsLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u>{chunks}</u>
                      </a>
                    ),
                    privacyPolicyLink: (chunks) => (
                      <a
                        className="text-primary"
                        href={settings?.privacyPolicyLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u>{chunks}</u>
                      </a>
                    ),
                    companyName: settings?.companyName || "",
                  }}
                />
              </div>
            </label>
          </div>
          <div className={"font-size-lg font-weight-bold mb-4"}>
            <FormattedMessage id="AUTH.GDPR.PERSONAL_MOBILE.TITLE" />
          </div>
          <div className="d-flex align-items-center flex-grow-1 mb-4">
            <label className="checkbox align-items-start">
              <input
                type="checkbox"
                checked={agreeMobile}
                onChange={() => setAgreeMobile(!agreeMobile)}
              />
              <span className="mr-4 flex-shrink-0" id="checkbox-phone-mobile" />
              <div>
                <FormattedMessage
                  id={"AUTH.GDPR.PERSONAL_MOBILE"}
                  values={{
                    span: (chunks) => (
                      <span className="text-nowrap font-weight-bold">{chunks}</span>
                    ),
                    mobile: formatPhoneNumberIntl(session.mobile),
                  }}
                />
              </div>
            </label>
          </div>
          <div className={"font-size-lg font-weight-bold mb-4"}>
            <FormattedMessage id="AUTH.GDPR.PERSONAL_EMAIL.TITLE" />
          </div>
          <div className="d-flex align-items-center flex-grow-1">
            <label className="checkbox align-items-start">
              <input
                type="checkbox"
                checked={agreeEmail}
                onChange={() => setAgreeEmail(!agreeEmail)}
              />
              <span className="mr-4 flex-shrink-0" id="checkbox-email" />
              <div>
                <FormattedMessage
                  id={"AUTH.GDPR.PERSONAL_EMAIL"}
                  values={{
                    span: (chunks) => (
                      <span className="text-nowrap font-weight-bold">{chunks}</span>
                    ),
                    email: session.email,
                  }}
                />
              </div>
            </label>
          </div>
          <div className="d-flex justify-content-end mt-8">
            <button className="btn btn-outline-danger font-weight-bold mr-6" onClick={logout}>
              <FormattedMessage id={"AUTH.GDPR.ACTION.DISAGREE"} />
            </button>
            <button
              disabled={!agreeGDPR || !agreeMobile || !agreeEmail}
              className="btn btn-primary font-weight-bold px-9 py-4"
              data-cy="button-gdpr-agree"
              onClick={acceptGDPR}
            >
              <FormattedMessage id={"AUTH.GDPR.ACTION.AGREE"} />
            </button>
          </div>
        </div>
      ) : (
        <div className={"spinner spinner-lg spinner-primary"}></div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(AcceptGDPR));
