/**
 * Here is a method to minimize (prevent?) decimal issue when working with float
 * In JS floats aren't handled well 0.1 + 0.2 = 0.30000000000000004 / 0.101 * 100 = 10.100000000000001
 * A more friendly solution exists but is less reliable : parseFloat(<value>.toFixed(<decimals>)
 * ex: parseFloat((0.155).toFixed(2)) => 0.15 instead of 0.16
 */
export const accurateFloatOperation = (value, maxDecimals) =>
  parseFloat(Math.round(`${value}e${maxDecimals}`) + "e-" + maxDecimals);

export const formatCurrency = (value, decimals, intl) =>
  intl.formatNumber(value, {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  }) + " €";
